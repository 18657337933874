import React from 'react';

function InfoColumnStaticAttribute({ id, label, value }) {
  return value ? (
    <div div id={`item-${id}`} className="fandb-items-wrap-preview-title">
      <span className="label">{label}</span>
      <span className="content">
        <ul>
          {value?.split('\n')?.map((feature, i) => (
            <li
              id={`item-${id}-value-${i + 1}`}
              key={`item-${id}-value-${i + 1}`}
              className="data description raw-text"
            >
              {feature}
            </li>
          ))}
        </ul>
      </span>
    </div>
  ) : null;
}

export default InfoColumnStaticAttribute;
