import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Input } from 'antd';
import { NUTRITIONAL_INFORMATION } from '../../../enums/AttributeType';
import { updateStagingAttribute } from '../../../actions';
import i18n from '../../../_i18n';
import { useDispatch, useSelector } from 'react-redux';
import AppLoader from '../../../components/AppLoader';
import { APPROVED, DELETE_APPROVED, DELETE_PENDING, PENDING, REJECTED } from '../../../enums/ApprovalStatus';
import RejectModal from '../../../components/modals/RejectModal';
import { attributeEditorDetails } from '../../../utils/Utils';
import Notification from '../../../components/Notification';
import Certifications from './Certifications';
import { NUMERIC_REGEX } from 'constants/Constants';

const checkNumeric = (isInvalid, value, msgDesc) => {
  const isNumeric = new RegExp(NUMERIC_REGEX);
  if (!isNumeric.test(value)) {
    Notification({
      message: i18n.t('nutritionalColumn.invalidType'),
      description: i18n.t(msgDesc),
      className: 'error',
      isClosable: true,
    });
    isInvalid = true;
  }
  return isInvalid;
};

const isInvalidNutritionalInfo = (value) => {
  let isInvalid;
  //check whether only numeric data are added to numeric fields
  isInvalid = checkNumeric(isInvalid, value.servingSize, 'nutritionalColumn.invalidServingSize');
  isInvalid = checkNumeric(isInvalid, value.calories, 'nutritionalColumn.invalidCalories');
  isInvalid = checkNumeric(isInvalid, value.totalFat, 'nutritionalColumn.invalidFat');
  isInvalid = checkNumeric(isInvalid, value.totalFatDV, 'nutritionalColumn.invalidFatDV');
  isInvalid = checkNumeric(isInvalid, value.saturated, 'nutritionalColumn.invalidSaturated');
  isInvalid = checkNumeric(isInvalid, value.saturatedDV, 'nutritionalColumn.invalidSaturatedDV');
  isInvalid = checkNumeric(isInvalid, value.transFat, 'nutritionalColumn.invalidTransFat');
  isInvalid = checkNumeric(isInvalid, value.carbohydrates, 'nutritionalColumn.invalidCarbohydrates');
  isInvalid = checkNumeric(isInvalid, value.fibre, 'nutritionalColumn.invalidFibre');
  isInvalid = checkNumeric(isInvalid, value.fibreDV, 'nutritionalColumn.invalidFibreDV');
  isInvalid = checkNumeric(isInvalid, value.sugars, 'nutritionalColumn.invalidSugars');
  isInvalid = checkNumeric(isInvalid, value.sugarsDV, 'nutritionalColumn.invalidSugarsDV');
  isInvalid = checkNumeric(isInvalid, value.proteins, 'nutritionalColumn.invalidProtein');
  isInvalid = checkNumeric(isInvalid, value.cholesterol, 'nutritionalColumn.invalidCholesterol');
  isInvalid = checkNumeric(isInvalid, value.sodium, 'nutritionalColumn.invalidSodium');
  isInvalid = checkNumeric(isInvalid, value.sodiumDV, 'nutritionalColumn.invalidSodiumDV');
  isInvalid = checkNumeric(isInvalid, value.potassium, 'nutritionalColumn.invalidPotassium');
  isInvalid = checkNumeric(isInvalid, value.potassiumDV, 'nutritionalColumn.invalidPotassiumDV');
  isInvalid = checkNumeric(isInvalid, value.calcium, 'nutritionalColumn.invalidCalcium');
  isInvalid = checkNumeric(isInvalid, value.calciumDV, 'nutritionalColumn.invalidCalciumDV');
  isInvalid = checkNumeric(isInvalid, value.iron, 'nutritionalColumn.invalidIron');
  isInvalid = checkNumeric(isInvalid, value.ironDV, 'nutritionalColumn.invalidIronDV');

  if (isInvalid) return true;

  //other validations
  let sugars = parseFloat(value.sugars);
  let carbohydrates = parseFloat(value.carbohydrates);
  if (carbohydrates < sugars) {
    Notification({
      message: i18n.t('nutritionalColumn.invalidInput'),
      description: i18n.t('nutritionalColumn.validateSugar'),
      className: 'error',
      isClosable: true,
    });
    return true;
  }
  let totalFat = parseFloat(value.totalFat);
  let saturated = parseFloat(value.saturated);
  let transFat = parseFloat(value.transFat);
  if (totalFat < saturated) {
    Notification({
      message: i18n.t('nutritionalColumn.invalidInput'),
      description: i18n.t('nutritionalColumn.validateSaturatedFat'),
      className: 'error',
      isClosable: true,
    });
    return true;
  }
  if (totalFat < transFat) {
    Notification({
      message: i18n.t('nutritionalColumn.invalidInput'),
      description: i18n.t('nutritionalColumn.validateTransFat'),
      className: 'error',
      isClosable: true,
    });
    return true;
  }
  if (totalFat < saturated + transFat) {
    Notification({
      message: i18n.t('nutritionalColumn.invalidInput'),
      description: i18n.t('nutritionalColumn.validateTotalFat'),
      className: 'error',
      isClosable: true,
    });
    return true;
  }
};

function getNutritionalInfo(nutritionalInfo, userType) {
  return nutritionalInfo?.nutritions?.attributeStatus === REJECTED &&
    nutritionalInfo?.nutritions?.authorRole !== userType
    ? nutritionalInfo?.nutritions?.previousValue
    : nutritionalInfo?.nutritions?.value;
}

function getFatDV(nutritionalInfo) {
  return nutritionalInfo?.nutritions?.value?.totalFatDV !== ''
    ? parseFloat(nutritionalInfo?.nutritions?.value?.totalFatDV).toFixed(2)
    : nutritionalInfo?.nutritions?.value?.totalFatDV;
}

function getSaturatedDV(nutritionalInfo) {
  return nutritionalInfo?.nutritions?.value?.saturatedDV !== ''
    ? parseFloat(nutritionalInfo?.nutritions?.value?.saturatedDV).toFixed(2)
    : nutritionalInfo?.nutritions?.value?.saturatedDV;
}

function getFibreDV(nutritionalInfo) {
  return nutritionalInfo?.nutritions?.value?.fibreDV !== ''
    ? parseFloat(nutritionalInfo?.nutritions?.value?.fibreDV).toFixed(2)
    : nutritionalInfo?.nutritions?.value?.fibreDV;
}

function getSugarsDV(nutritionalInfo) {
  return nutritionalInfo?.nutritions?.value?.sugarsDV !== ''
    ? parseFloat(nutritionalInfo?.nutritions?.value?.sugarsDV).toFixed(2)
    : nutritionalInfo?.nutritions?.value?.sugarsDV;
}

function getSodiumDV(nutritionalInfo) {
  return nutritionalInfo?.nutritions?.value?.sodiumDV !== ''
    ? parseFloat(nutritionalInfo?.nutritions?.value?.sodiumDV).toFixed(2)
    : nutritionalInfo?.nutritions?.value?.sodiumDV;
}

function getPotassiumDV(nutritionalInfo) {
  return nutritionalInfo?.nutritions?.value?.potassiumDV !== ''
    ? parseFloat(nutritionalInfo?.nutritions?.value?.potassiumDV).toFixed(2)
    : nutritionalInfo?.nutritions?.value?.potassiumDV;
}

function getCalciumDV(nutritionalInfo) {
  return nutritionalInfo?.nutritions?.value?.calciumDV !== ''
    ? parseFloat(nutritionalInfo?.nutritions?.value?.calciumDV).toFixed(2)
    : nutritionalInfo?.nutritions?.value?.calciumDV;
}

function getIronDV(nutritionalInfo) {
  return nutritionalInfo?.nutritions?.value?.ironDV !== ''
    ? parseFloat(nutritionalInfo?.nutritions?.value?.ironDV).toFixed(2)
    : nutritionalInfo?.nutritions?.value?.ironDV;
}

const nutrionalPanelCssAppliier = (nutritionalInfo, userType) => {
  if (nutritionalInfo?.nutritions?.attributeStatus === PENDING) {
    return 'nutrition-block adjusted-for-source pending';
  } else if (
    nutritionalInfo?.nutritions?.attributeStatus === APPROVED &&
    nutritionalInfo?.nutritions?.authorRole === userType
  ) {
    return 'nutrition-block adjusted-for-source nutrition-fact-block-approved';
  } else if (
    nutritionalInfo?.nutritions?.attributeStatus === REJECTED &&
    nutritionalInfo?.nutritions?.authorRole === userType
  ) {
    return 'nutrition-block adjusted-for-source nutrition-fact-block-rejected';
  } else {
    return 'nutrition-block adjusted-for-source';
  }
};

const nutrionalPanelEditsCssAppliier = (nutritionalInfo) => {
  if (nutritionalInfo?.nutritions?.attributeStatus === APPROVED) {
    return 'nutrition-fact-approved';
  } else {
    return 'nutrition-fact-rejected';
  }
};

const nutritionalPanelStatusApplier = (nutritionalInfo) => {
  if (
    nutritionalInfo?.nutritions?.attributeStatus === PENDING ||
    nutritionalInfo?.nutritions?.attributeStatus === DELETE_PENDING
  ) {
    return 'nutrition-fact-edit-by-pending';
  } else if (
    nutritionalInfo?.nutritions?.attributeStatus === APPROVED ||
    nutritionalInfo?.nutritions?.attributeStatus === DELETE_APPROVED
  ) {
    return 'nutrition-fact-edit-by-approved';
  } else {
    return 'nutrition-fact-edit-by-rejected';
  }
};

const getNutritionValue = (nutritionValue) => {
  if (nutritionValue !== '') {
    return parseFloat(nutritionValue).toFixed(2);
  } else {
    return nutritionValue;
  }
};

function isFetching(updateFetching, approveFetching) {
  return updateFetching || approveFetching;
}

function NutritionalColumn({ nutritionalInfo, supc, coreData, permissions }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    data: { fullName: userId, userRole: userType, associatedUsers },
  } = useSelector((state) => state?.userData);

  const { fetching: updateFetching } = useSelector((state) => state?.updateAttribute);
  const { fetching: approveFetching } = useSelector((state) => state?.approvePendingFile);

  const [stagingAttribute, setStagingAttribute] = useState(null);
  const [isnutritionalInfoEditing, setIsNutritionalInfoEditing] = useState(false);
  const [nutritionlRejectModal, setNutritionlRejectModal] = useState(false);
  const [nutritionalInformation, setNutritionalInformation] = useState(getNutritionalInfo(nutritionalInfo, userType));

  const [servingSizeEdit, setServingSizeEdit] = useState(nutritionalInfo?.nutritions?.value?.servingSize);
  const [servingSizeUOMEdit, setServingSizeUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.servingSizeUOM);
  const [caloriesEdit, setCaloriesEdit] = useState(nutritionalInfo?.nutritions?.value?.calories);
  const [totalFatEdit, setTotalFatEdit] = useState(nutritionalInfo?.nutritions?.value?.totalFat);
  const [totalFatUOMEdit, setTotalFatUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.totalFatUOM);
  const [totalFatDVEdit, setTotalFatDVEdit] = useState(getFatDV(nutritionalInfo));
  const [saturatedEdit, setSaturatedEdit] = useState(nutritionalInfo?.nutritions?.value?.saturated);
  const [saturatedUOMEdit, setSaturatedUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.saturatedUOM);
  const [saturatedDVEdit, setSaturatedDVEdit] = useState(getSaturatedDV(nutritionalInfo));
  const [transFatEdit, setTransFatEdit] = useState(nutritionalInfo?.nutritions?.value?.transFat);
  const [transFatUOMEdit, setTransFatUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.transFatUOM);
  const [carbohydratesEdit, setCarbohydratesEdit] = useState(nutritionalInfo?.nutritions?.value?.carbohydrates);
  const [carbohydratesUOMEdit, setCarbohydratesUOMEdit] = useState(
    nutritionalInfo?.nutritions?.value?.carbohydratesUOM
  );
  const [fibreEdit, setFibreEdit] = useState(nutritionalInfo?.nutritions?.value?.fibre);
  const [fibreUOMEdit, setFibreUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.fibreUOM);
  const [fibreDVEdit, setFibreDVEdit] = useState(getFibreDV(nutritionalInfo));
  const [sugarsEdit, setSugarsEdit] = useState(nutritionalInfo?.nutritions?.value?.sugars);
  const [sugarsUOMEdit, setSugarsUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.sugarsUOM);
  const [sugarsDVEdit, setSugarsDVEdit] = useState(getSugarsDV(nutritionalInfo));
  const [proteinsEdit, setProteinsEdit] = useState(nutritionalInfo?.nutritions?.value?.proteins);
  const [proteinsUOMEdit, setProteinsUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.proteinsUOM);
  const [cholesterolEdit, setCholesterolEdit] = useState(nutritionalInfo?.nutritions?.value?.cholesterol);
  const [cholesterolUOMEdit, setCholesterolUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.cholesterolUOM);
  const [sodiumEdit, setSodiumEdit] = useState(nutritionalInfo?.nutritions?.value?.sodium);
  const [sodiumUOMEdit, setSodiumUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.sodiumUOM);
  const [sodiumDVEdit, setSodiumDVEdit] = useState(getSodiumDV(nutritionalInfo));
  const [potassiumEdit, setPotassiumEdit] = useState(nutritionalInfo?.nutritions?.value?.potassium);
  const [potassiumUOMEdit, setPotassiumUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.potassiumUOM);
  const [potassiumDVEdit, setPotassiumDVEdit] = useState(getPotassiumDV(nutritionalInfo));
  const [calciumEdit, setCalciumEdit] = useState(nutritionalInfo?.nutritions?.value?.calcium);
  const [calciumUOMEdit, setCalciumUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.calciumUOM);
  const [calciumDVEdit, setCalciumDVEdit] = useState(getCalciumDV(nutritionalInfo));
  const [ironEdit, setIronEdit] = useState(nutritionalInfo?.nutritions?.value?.iron);
  const [ironUOMEdit, setIronUOMEdit] = useState(nutritionalInfo?.nutritions?.value?.ironUOM);
  const [ironDVEdit, setIronDVEdit] = useState(getIronDV(nutritionalInfo));

  // Functionality commented since not used
  const clearNutritionalInformationEdits = () => {
    setServingSizeEdit(nutritionalInfo?.nutritions?.value?.servingSize);
    setServingSizeUOMEdit(nutritionalInfo?.nutritions?.value?.servingSizeUOM);
    setCaloriesEdit(nutritionalInfo?.nutritions?.value?.calories);
    setTotalFatEdit(nutritionalInfo?.nutritions?.value?.totalFat);
    setTotalFatUOMEdit(nutritionalInfo?.nutritions?.value?.totalFatUOM);
    setTotalFatDVEdit(getFatDV(nutritionalInfo));
    setSaturatedEdit(nutritionalInfo?.nutritions?.value?.saturated);
    setSaturatedUOMEdit(nutritionalInfo?.nutritions?.value?.saturatedUOM);
    setSaturatedDVEdit(getSaturatedDV(nutritionalInfo));
    setTransFatEdit(nutritionalInfo?.nutritions?.value?.transFat);
    setTransFatUOMEdit(nutritionalInfo?.nutritions?.value?.transFatUOM);
    setCarbohydratesEdit(nutritionalInfo?.nutritions?.value?.carbohydrates);
    setCarbohydratesUOMEdit(nutritionalInfo?.nutritions?.value?.carbohydratesUOM);
    setFibreEdit(nutritionalInfo?.nutritions?.value?.fibre);
    setFibreUOMEdit(nutritionalInfo?.nutritions?.value?.fibreUOM);
    setFibreDVEdit(getFibreDV(nutritionalInfo));
    setSugarsEdit(nutritionalInfo?.nutritions?.value?.sugars);
    setSugarsUOMEdit(nutritionalInfo?.nutritions?.value?.sugarsUOM);
    setSugarsDVEdit(getSugarsDV(nutritionalInfo));
    setProteinsEdit(nutritionalInfo?.nutritions?.value?.proteins);
    setProteinsUOMEdit(nutritionalInfo?.nutritions?.value?.proteinsUOM);
    setCholesterolEdit(nutritionalInfo?.nutritions?.value?.cholesterol);
    setCholesterolUOMEdit(nutritionalInfo?.nutritions?.value?.cholesterolUOM);
    setSodiumEdit(nutritionalInfo?.nutritions?.value?.sodium);
    setSodiumUOMEdit(nutritionalInfo?.nutritions?.value?.sodiumUOM);
    setSodiumDVEdit(getSodiumDV(nutritionalInfo));
    setPotassiumEdit(nutritionalInfo?.nutritions?.value?.potassium);
    setPotassiumUOMEdit(nutritionalInfo?.nutritions?.value?.potassiumUOM);
    setPotassiumDVEdit(getPotassiumDV(nutritionalInfo));
    setCalciumEdit(nutritionalInfo?.nutritions?.value?.calcium);
    setCalciumUOMEdit(nutritionalInfo?.nutritions?.value?.calciumUOM);
    setCalciumDVEdit(getCalciumDV(nutritionalInfo));
    setIronEdit(nutritionalInfo?.nutritions?.value?.iron);
    setIronUOMEdit(nutritionalInfo?.nutritions?.value?.ironUOM);
    setIronDVEdit(getIronDV(nutritionalInfo));
  };

  useEffect(() => {
    stagingAttribute &&
      dispatch(updateStagingAttribute(coreData?.supc, i18n.language, stagingAttribute, associatedUsers));
  }, [stagingAttribute]);

  const updateNutritionalInfo = (currentNutritionalInfo) => {
    currentNutritionalInfo['servingSize'] = servingSizeEdit;
    currentNutritionalInfo['servingSizeUOM'] = servingSizeUOMEdit;
    currentNutritionalInfo['calories'] = caloriesEdit;
    currentNutritionalInfo['saturatedUOM'] = saturatedUOMEdit;
    currentNutritionalInfo['totalFat'] = totalFatEdit;
    currentNutritionalInfo['totalFatUOM'] = totalFatUOMEdit;
    currentNutritionalInfo['totalFatDV'] = totalFatDVEdit;
    currentNutritionalInfo['saturated'] = saturatedEdit;
    currentNutritionalInfo['saturatedDV'] = saturatedDVEdit;
    currentNutritionalInfo['transFat'] = transFatEdit;
    currentNutritionalInfo['transFatUOM'] = transFatUOMEdit;
    currentNutritionalInfo['carbohydrates'] = carbohydratesEdit;
    currentNutritionalInfo['carbohydratesUOM'] = carbohydratesUOMEdit;
    currentNutritionalInfo['fibre'] = fibreEdit;
    currentNutritionalInfo['fibreUOM'] = fibreUOMEdit;
    currentNutritionalInfo['fibreDV'] = fibreDVEdit;
    currentNutritionalInfo['sugars'] = sugarsEdit;
    currentNutritionalInfo['sugarsUOM'] = sugarsUOMEdit;
    currentNutritionalInfo['sugarsDV'] = sugarsDVEdit;
    currentNutritionalInfo['proteins'] = proteinsEdit;
    currentNutritionalInfo['proteinsUOM'] = proteinsUOMEdit;
    currentNutritionalInfo['cholesterol'] = cholesterolEdit;
    currentNutritionalInfo['cholesterolUOM'] = cholesterolUOMEdit;
    currentNutritionalInfo['sodium'] = sodiumEdit;
    currentNutritionalInfo['sodiumUOM'] = sodiumUOMEdit;
    currentNutritionalInfo['sodiumDV'] = sodiumDVEdit;
    currentNutritionalInfo['potassium'] = potassiumEdit;
    currentNutritionalInfo['potassiumUOM'] = potassiumUOMEdit;
    currentNutritionalInfo['potassiumDV'] = potassiumDVEdit;
    currentNutritionalInfo['calcium'] = calciumEdit;
    currentNutritionalInfo['calciumUOM'] = calciumUOMEdit;
    currentNutritionalInfo['calciumDV'] = calciumDVEdit;
    currentNutritionalInfo['iron'] = ironEdit;
    currentNutritionalInfo['ironUOM'] = ironUOMEdit;
    currentNutritionalInfo['ironDV'] = ironDVEdit;

    return currentNutritionalInfo;
  };

  const prepareUpdatedNutritionalInformation = () => {
    let currentNutritionalInfo = nutritionalInformation;
    return updateNutritionalInfo(currentNutritionalInfo);
  };

  const handleNutritionalInfoSave = (value, status, comment, previousValue) => {
    if (isInvalidNutritionalInfo(value)) {
      setIsNutritionalInfoEditing(true);
    } else {
      setIsNutritionalInfoEditing(false);
      const attribute = {
        attributeType: NUTRITIONAL_INFORMATION,
        value: value,
        previousValue: previousValue,
        attributeStatus: status,
        comment,
        author: userId,
        timestamp: null,
        authorRole: userType,
      };
      setStagingAttribute(attribute);
    }
  };

  return isFetching(updateFetching, approveFetching) ? (
    <AppLoader />
  ) : (
    <div id="nutritional-column" className="nutritional-column animated fadeIn delay-2s">
      {console.log('isnutritionalInfoEditing: ', isnutritionalInfoEditing)}
      {nutritionalInfo?.nutritions?.value && isnutritionalInfoEditing && (
        <div
          id="nutritional-column-nutrition-block"
          className={`nutrition-block adjusted-for-source nutrition-fact-block-editable`}
        >
          {/* <div id="info-column-overview-edits-pending" className="nutrition-fact-pending">
            EDITS PENDING APPROVAL
          </div> */}
          <header id="nutritional-column-nutrition-block-header-editable">
            Nutrition Facts
            <div id="nutritional-column-nutrition-block-header-french-editable" className="french">
              Valeur nutritive
            </div>
          </header>
          <div id="nutritional-column-nutrition-block-serving-size-editable" className="serving-size">
            Calculation Size
            <Input
              id="nutrition-fact-data-edit-form-input-servingSize"
              placeholder="12.00"
              className="nutrition-fact-data-edit-form-input"
              value={servingSizeEdit}
              onChange={(e) => {
                setServingSizeEdit(e.target.value);
              }}
            />
            <Input
              id="nutrition-fact-data-edit-form-input-measurement-servingSizeUOM"
              placeholder="mg"
              className="nutrition-fact-data-edit-form-input-measurement"
              value={servingSizeUOMEdit}
              onChange={(e) => {
                setServingSizeUOMEdit(e.target.value);
              }}
            />
            <div id="nutritional-column-nutrition-block-portion-editable" className="french">
              Portion calculée
              {/* <Input placeholder="12.00" className="nutrition-fact-data-edit-form-input" />
              <Input placeholder="mg" className="nutrition-fact-data-edit-form-input-measurement" /> */}
            </div>
          </div>

          <div id="nutritional-column-nutrition-block-calories-editable" className="nutrition-row calories no-border">
            <div id="nutritional-column-nutrition-block-calories-value-editable" className="label">
              Calories
              <Input
                id="nutrition-fact-data-edit-form-input-calories"
                className="nutrition-fact-data-edit-form-input"
                placeholder="100.00"
                value={caloriesEdit}
                onChange={(e) => {
                  setCaloriesEdit(e.target.value);
                }}
              />
            </div>

            <div id="nutritional-column-nutrition-block-calories-daily-value-editable" className="dv">
              % Daily Value*
              <div id="nutritional-column-nutrition-block-calories-daily-value-french-editable" className="french">
                % Valeur quotidienne*
              </div>
            </div>
          </div>

          <div id="nutritional-column-nutrition-block-fat-editable" className="nutrition-row section-level-1 no-border">
            <div id="nutritional-column-nutrition-block-fat-label-editable" className="label">
              Fat / Lipides
              <span id="nutritional-column-nutrition-block-fat-data-editable" className="">
                <Input
                  id="nutrition-fact-data-edit-form-input-totalFat"
                  value={totalFatEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setTotalFatEdit(e.target.value);
                  }}
                />{' '}
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-totalFatUOM"
                  value={totalFatUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setTotalFatUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>

            <div
              id="nutritional-column-nutrition-block-fat-daily-value-editable"
              className="dv-data nutrition-fact-percentage-value"
            >
              <Input
                id="nutrition-fact-data-edit-form-input-percentage-totalFatDV"
                value={totalFatDVEdit}
                placeholder="100"
                className="nutrition-fact-data-edit-form-input-percentage"
                onChange={(e) => {
                  setTotalFatDVEdit(e.target.value);
                }}
              />
              %
            </div>
          </div>

          <div
            id="nutritional-column-nutrition-block-saturated-editable"
            className="nutrition-row sub-section no-border"
          >
            <div id="nutritional-column-nutrition-block-saturated-label-editable" className="label">
              Saturated / Saturés
              <span id="nutritional-column-nutrition-block-saturated-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-saturated"
                  value={saturatedEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setSaturatedEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-saturatedUOM"
                  value={saturatedUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setSaturatedUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>

            <div
              id="nutritional-column-nutrition-block-saturated-daily-value-editable"
              className="dv-data nutrition-fact-percentage-value "
            >
              <Input
                id="nutrition-fact-data-edit-form-input-percentage-saturatedDV"
                value={saturatedDVEdit}
                placeholder="100"
                className="nutrition-fact-data-edit-form-input-percentage"
                onChange={(e) => {
                  setSaturatedDVEdit(e.target.value);
                }}
              />
              %
            </div>
          </div>

          <div id="nutritional-column-nutrition-block-trans-editable" className="nutrition-row sub-section">
            <div id="nutritional-column-nutrition-block-trans-label-editable" className="label">
              + Trans / trans
              <span id="nutritional-column-nutrition-block-trans-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-transFat"
                  value={transFatEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setTransFatEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-transFatUOM"
                  value={transFatUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setTransFatUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>
          </div>
          <div
            id="nutritional-column-nutrition-block-carbo-editable"
            className="nutrition-row section-level-1 no-border"
          >
            <div id="nutritional-column-nutrition-block-carbo-label-editable" className="label">
              Carbohydrate / Glucides
              <span id="nutritional-column-nutrition-block-carbo-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-carbohydrates"
                  value={carbohydratesEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setCarbohydratesEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-carbohydratesUOM"
                  value={carbohydratesUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setCarbohydratesUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-fibre-editable" className="nutrition-row sub-section no-border">
            <div id="nutritional-column-nutrition-block-fibre-label-editable" className="label">
              Fibre / Fibres
              <span id="nutritional-column-nutrition-block-fibre-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-fibre"
                  value={fibreEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setFibreEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-fibreUOM"
                  value={fibreUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setFibreUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>

            <div
              id="nutritional-column-nutrition-block-fibre-daily-value-editable"
              className="dv-data nutrition-fact-percentage-value"
            >
              <Input
                id="nutrition-fact-data-edit-form-input-percentage-fibreDV"
                value={fibreDVEdit}
                placeholder="100"
                className="nutrition-fact-data-edit-form-input-percentage"
                onChange={(e) => {
                  setFibreDVEdit(e.target.value);
                }}
              />
              %
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-sugars-editable" className="nutrition-row sub-section">
            <div id="nutritional-column-nutrition-block-sugars-label-editable" className="label">
              Sugars / Sucres
              <span id="nutritional-column-nutrition-block-sugars-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-sugars"
                  value={sugarsEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setSugarsEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-sugarsUOM"
                  value={sugarsUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setSugarsUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>

            <div
              id="nutritional-column-nutrition-block-sugars-daily-value-editable"
              className="dv-data nutrition-fact-percentage-value "
            >
              <Input
                id="nutrition-fact-data-edit-form-input-percentage-sugarsDV"
                value={sugarsDVEdit}
                placeholder="100"
                className="nutrition-fact-data-edit-form-input-percentage"
                onChange={(e) => {
                  setSugarsDVEdit(e.target.value);
                }}
              />
              %
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-protein-editable" className="nutrition-row section-level-1">
            <div id="nutritional-column-nutrition-block-protein-label-editable" className="label">
              Protein / Protéines
              <span id="nutritional-column-nutrition-block-protein-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-proteins"
                  value={proteinsEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setProteinsEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-proteinsUOM"
                  value={proteinsUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setProteinsUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-cholesterol-editable" className="nutrition-row section-level-1">
            <div id="nutritional-column-nutrition-block-cholesterol-label-editable" className="label">
              Cholesterol
              <span id="nutritional-column-nutrition-block-cholesterol-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-cholesterol"
                  value={cholesterolEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setCholesterolEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-cholesterolUOM"
                  value={cholesterolUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setCholesterolUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>
          </div>
          <div
            id="nutritional-column-nutrition-block-sodium-editable"
            className="nutrition-row section-level-1 divider-bottom"
          >
            <div id="nutritional-column-nutrition-block-sodium-label-editable" className="label">
              Sodium
              <span id="nutritional-column-nutrition-block-sodium-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-sodium"
                  value={sodiumEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setSodiumEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-sodiumUOM"
                  value={sodiumUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setSodiumUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>

            <div
              id="nutritional-column-nutrition-block-sodium-daily-value-editable"
              className="dv-data nutrition-fact-percentage-value "
            >
              <Input
                id="nutrition-fact-data-edit-form-input-percentage-sodiumDV"
                value={sodiumDVEdit}
                placeholder="100"
                className="nutrition-fact-data-edit-form-input-percentage"
                onChange={(e) => {
                  setSodiumDVEdit(e.target.value);
                }}
              />
              %
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-potassium-editable" className="nutrition-row">
            <div id="nutritional-column-nutrition-block-potassium-label-editable" className="label">
              Potassium
              <span id="nutritional-column-nutrition-block-potassium-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-potassium"
                  value={potassiumEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setPotassiumEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-potassiumUOM"
                  value={potassiumUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setPotassiumUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>
            <div
              id="nutritional-column-nutrition-block-potassium-daily-value-editable"
              className="dv-data nutrition-fact-percentage-value "
            >
              <Input
                id="nutrition-fact-data-edit-form-input-percentage-potassiumDV"
                value={potassiumDVEdit}
                placeholder="100"
                className="nutrition-fact-data-edit-form-input-percentage"
                onChange={(e) => {
                  setPotassiumDVEdit(e.target.value);
                }}
              />
              %
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-calcium-editable" className="nutrition-row">
            <div id="nutritional-column-nutrition-block-calcium-label-editable" className="label">
              Calcium
              <span id="nutritional-column-nutrition-block-calcium-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-calcium"
                  value={calciumEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setCalciumEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-calciumUOM"
                  value={calciumUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setCalciumUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>

            <div
              id="nutritional-column-nutrition-block-calcium-daily-value-editable"
              className="dv-data nutrition-fact-percentage-value "
            >
              <Input
                id="nutrition-fact-data-edit-form-input-percentage-calciumDV"
                value={calciumDVEdit}
                placeholder="100"
                className="nutrition-fact-data-edit-form-input-percentage"
                onChange={(e) => {
                  setCalciumDVEdit(e.target.value);
                }}
              />
              %
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-iron-editable" className="nutrition-row divider-bottom">
            <div id="nutritional-column-nutrition-block-iron-label-editable" className="label">
              Iron / Fer
              <span id="nutritional-column-nutrition-block-iron-data-editable" className="data">
                <Input
                  id="nutrition-fact-data-edit-form-input-iron"
                  value={ironEdit}
                  placeholder="100.00"
                  className="nutrition-fact-data-edit-form-input"
                  onChange={(e) => {
                    setIronEdit(e.target.value);
                  }}
                />
                <Input
                  id="nutrition-fact-data-edit-form-input-measurement-ironUOM"
                  value={ironUOMEdit}
                  placeholder="mg"
                  className="nutrition-fact-data-edit-form-input-measurement"
                  onChange={(e) => {
                    setIronUOMEdit(e.target.value);
                  }}
                />
              </span>
            </div>

            <div
              id="nutritional-column-nutrition-block-iron-daily-value-editable"
              className="dv-data nutrition-fact-percentage-value "
            >
              <Input
                id="nutrition-fact-data-edit-form-input-percentage-ironDV"
                value={ironDVEdit}
                placeholder="100"
                className="nutrition-fact-data-edit-form-input-percentage"
                onChange={(e) => {
                  setIronDVEdit(e.target.value);
                }}
              />
              %
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-dv-note-editable" className="nutrition-row dv-note">
            *5% or less is <span> a little</span>, 15% or more is <span> a lot</span>
            <div id="nutritional-column-nutrition-block-dv-note-french-editable" className="french">
              *5% ou moins c'est un <span>peu</span>, 15% ou plus c'est <span>beaucoup</span>
            </div>
          </div>
          <div id="nutritional-column-overview-data-description-edit-textarea-footer" class="footer-bar">
            <div
              id="nutritional-column-overview-data-description-edit-textarea-footer-actions  "
              class="footer-actions nutrition-fact-action-button-wrap"
            >
              <button
                id="nutritional-column-overview-data-description-edit-textarea-footer-actions-save"
                type="button"
                class="ant-btn btn green-action-btn invert"
                onClick={() => {
                  handleNutritionalInfoSave(prepareUpdatedNutritionalInformation(), PENDING, null);
                }}
              >
                <i
                  aria-label="icon: check"
                  id="nutritional-column-overview-data-description-edit-textarea-footer-actions-save-icon"
                  class="anticon anticon-check"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    className=""
                    data-icon="check"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                  </svg>
                </i>
                <span> Save</span>
              </button>
              <button
                id="nutritional-column-overview-data-description-edit-textarea-footer-actions-cancel"
                type="button"
                class="ant-btn btn red-action-btn invert"
                onClick={() => {
                  setIsNutritionalInfoEditing(false);
                  clearNutritionalInformationEdits();
                }}
              >
                <i
                  aria-label="icon: close"
                  id="nutritional-column-overview-data-description-edit-textarea-footer-actions-cancel-icon"
                  class="anticon anticon-close"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    class=""
                    data-icon="close"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </i>
                <span> Cancel</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {nutritionalInfo?.nutritions?.value && !isnutritionalInfoEditing && (
        <div className={nutrionalPanelCssAppliier(nutritionalInfo, userType)}>
          {nutritionalInfo?.nutritions?.attributeStatus === PENDING && (
            <div id="nutritional-column-overview-approvalble" className="nutrional-label-actions">
              <div id="nutritional-column-overview-edits-pending" className="nutrition-fact-pending">
                {i18n.t('infoColumn.editsPending')}
              </div>
              {permissions?.NUTRITIONAL_INFORMATION?.approvable && (
                <React.Fragment>
                  <Button
                    id="nutritional-column-overview-approvalble-approve"
                    className="btn green-action-btn invert"
                    onClick={() => {
                      handleNutritionalInfoSave(nutritionalInformation, APPROVED, null);
                    }}
                  >
                    <Icon id="nutritional-column-overview-approvalble-approve-icon" type="check" />
                    {i18n.t('infoColumn.approve')}
                  </Button>
                  <Button
                    id="nutritional-column-overview-approvalble-reject"
                    className="btn red-action-btn invert"
                    onClick={() => {
                      setNutritionlRejectModal(true);
                    }}
                  >
                    <Icon id="nutritional-column-overview-approvalble-reject-icon" type="close" />
                    {i18n.t('infoColumn.reject')}
                  </Button>
                </React.Fragment>
              )}
            </div>
          )}

          {(nutritionalInfo?.nutritions?.attributeStatus === APPROVED ||
            nutritionalInfo?.nutritions?.attributeStatus === REJECTED) &&
            nutritionalInfo?.nutritions?.authorRole === userType && (
              <div id="nutritional-column-nutrition-fact-status" className="nutrition-fact-status-wrap">
                <div
                  id="nutritional-column-overview-edits-pending"
                  className={nutrionalPanelEditsCssAppliier(nutritionalInfo)}
                >
                  {nutritionalInfo?.nutritions?.attributeStatus}
                </div>
                <button
                  id="nutritional-column-info-row-datablock-completed-reject-button"
                  type="button"
                  class="ant-btn btn btn-dismiss"
                  onClick={() => {
                    setNutritionalInformation(nutritionalInfo?.nutritions?.previousValue);
                    handleNutritionalInfoSave(nutritionalInformation, null, null);
                  }}
                >
                  <span>{i18n.t('infoColumn.dismiss')} </span>
                </button>
              </div>
            )}

          <header id="nutritional-column-nutrition-block-header">
            Nutrition Facts
            <div id="nutritional-column-nutrition-block-header-french" className="french">
              Valeur nutritive
            </div>
          </header>

          <div id="nutritional-column-nutrition-block-serving-size" className="serving-size serving-size-layout">
            <div className="label-column">
              <div className="english">Calculation size /</div>
              <div className="french">Portion calculée</div>
            </div>
            <div className="serving-value">
              {nutritionalInformation?.servingSize}
              {nutritionalInformation?.servingSizeUOM}
            </div>
          </div>

          <div id="nutritional-column-nutrition-block-calories" className="nutrition-row calories no-border">
            <div id="nutritional-column-nutrition-block-calories-value" className="label">
              Calories{' '}
              <span id="nutritional-column-nutrition-block-calories-data" className="data">
                {nutritionalInformation?.calories}
              </span>
            </div>

            <div id="nutritional-column-nutrition-block-calories-daily-value" className="dv">
              % Daily Value*
              <div id="nutritional-column-nutrition-block-calories-daily-value-french" className="french">
                % Valeur quotidienne*
              </div>
            </div>
          </div>

          <div id="nutritional-column-nutrition-block-fat" className="nutrition-row section-level-1 no-border">
            <div id="nutritional-column-nutrition-block-fat-label" className="label">
              Fat / Lipides
              <span id="nutritional-column-nutrition-block-fat-data" className="data">
                {nutritionalInformation?.totalFat}
                {nutritionalInformation?.totalFatUOM}
              </span>
            </div>

            <div id="nutritional-column-nutrition-block-fat-daily-value" className="dv-data ">
              {getNutritionValue(nutritionalInformation?.totalFatDV)}%
            </div>
          </div>

          <div id="nutritional-column-nutrition-block-saturated" className="nutrition-row sub-section no-border">
            <div id="nutritional-column-nutrition-block-saturated-label" className="label">
              Saturated / Saturés
              <span id="nutritional-column-nutrition-block-saturated-data" className="data">
                {nutritionalInformation?.saturated}
                {nutritionalInformation?.saturatedUOM}
              </span>
            </div>

            <div id="nutritional-column-nutrition-block-saturated-daily-value" className="dv-data ">
              {getNutritionValue(nutritionalInformation?.saturatedDV)}%
            </div>
          </div>

          <div id="nutritional-column-nutrition-block-trans" className="nutrition-row sub-section">
            <div id="nutritional-column-nutrition-block-trans-label" className="label">
              + Trans / trans
              <span id="nutritional-column-nutrition-block-trans-data" className="data">
                {nutritionalInformation?.transFat}
                {nutritionalInformation?.transFatUOM}
              </span>
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-carbo" className="nutrition-row section-level-1 no-border">
            <div id="nutritional-column-nutrition-block-carbo-label" className="label">
              Carbohydrate / Glucides
              <span id="nutritional-column-nutrition-block-carbo-data" className="data">
                {nutritionalInformation?.carbohydrates}
                {nutritionalInformation?.carbohydratesUOM}
              </span>
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-fibre" className="nutrition-row sub-section no-border">
            <div id="nutritional-column-nutrition-block-fibre-label" className="label">
              Fibre / Fibres
              <span id="nutritional-column-nutrition-block-fibre-data" className="data">
                {nutritionalInformation?.fibre}
                {nutritionalInformation?.fibreUOM}
              </span>
            </div>

            <div id="nutritional-column-nutrition-block-fibre-daily-value" className="dv-data ">
              {getNutritionValue(nutritionalInformation?.fibreDV)}%
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-sugars" className="nutrition-row sub-section">
            <div id="nutritional-column-nutrition-block-sugars-label" className="label">
              Sugars / Sucres
              <span id="nutritional-column-nutrition-block-sugars-data" className="data">
                {nutritionalInformation?.sugars}
                {nutritionalInformation?.sugarsUOM}
              </span>
            </div>

            <div id="nutritional-column-nutrition-block-sugars-daily-value" className="dv-data ">
              {getNutritionValue(nutritionalInformation?.sugarsDV)}%
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-protein" className="nutrition-row section-level-1">
            <div id="nutritional-column-nutrition-block-protein-label" className="label">
              Protein / Protéines
              <span id="nutritional-column-nutrition-block-protein-data" className="data">
                {nutritionalInformation?.proteins}
                {nutritionalInformation?.proteinsUOM}
              </span>
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-cholesterol" className="nutrition-row section-level-1">
            <div id="nutritional-column-nutrition-block-cholesterol-label" className="label">
              Cholesterol
              <span id="nutritional-column-nutrition-block-cholesterol-data" className="data">
                {nutritionalInformation?.cholesterol}
                {nutritionalInformation?.cholesterolUOM}
              </span>
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-sodium" className="nutrition-row section-level-1 divider-bottom">
            <div id="nutritional-column-nutrition-block-sodium-label" className="label">
              Sodium
              <span id="nutritional-column-nutrition-block-sodium-data" className="data">
                {nutritionalInformation?.sodium}
                {nutritionalInformation?.sodiumUOM}
              </span>
            </div>

            <div id="nutritional-column-nutrition-block-sodium-daily-value" className="dv-data ">
              {getNutritionValue(nutritionalInformation?.sodiumDV)}%
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-potassium" className="nutrition-row">
            <div id="nutritional-column-nutrition-block-potassium-label" className="label">
              Potassium
              <span id="nutritional-column-nutrition-block-potassium-data" className="data">
                {nutritionalInformation?.potassium}
                {nutritionalInformation?.potassiumUOM}
              </span>
            </div>
            <div id="nutritional-column-nutrition-block-potassium-daily-value" className="dv-data ">
              {getNutritionValue(nutritionalInformation?.potassiumDV)}%
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-calcium" className="nutrition-row">
            <div id="nutritional-column-nutrition-block-calcium-label" className="label">
              Calcium
              <span id="nutritional-column-nutrition-block-calcium-data" className="data">
                {nutritionalInformation?.calcium}
                {nutritionalInformation?.calciumUOM}
              </span>
            </div>

            <div id="nutritional-column-nutrition-block-calcium-daily-value" className="dv-data ">
              {getNutritionValue(nutritionalInformation?.calciumDV)}%
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-iron" className="nutrition-row divider-bottom">
            <div id="nutritional-column-nutrition-block-iron-label" className="label">
              Iron / Fer
              <span id="nutritional-column-nutrition-block-iron-data" className="data">
                {nutritionalInformation?.iron}
                {nutritionalInformation?.ironUOM}
              </span>
            </div>

            <div id="nutritional-column-nutrition-block-iron-daily-value" className="dv-data ">
              {getNutritionValue(nutritionalInformation?.ironDV)}%
            </div>
          </div>
          <div id="nutritional-column-nutrition-block-dv-note" className="nutrition-row dv-note">
            *5% or less is <span> a little</span>, 15% or more is <span> a lot</span>
            <div id="nutritional-column-nutrition-block-dv-note-french" className="french">
              *5% ou moins c'est un <span>peu</span>, 15% ou plus c'est <span>beaucoup</span>
            </div>
          </div>
          <div className="nutrition-row dv-note nutrition-fact-edit-button-wrap">
            {permissions.NUTRITIONAL_INFORMATION.editable && nutritionalInfo?.nutritions?.identifier === 'N' && (
              <Button
                id="nutrition-row-dv-note-nutrition-fact-edit-button-click"
                type="dashed"
                onClick={() => {
                  setIsNutritionalInfoEditing(true);
                }}
              >
                <div className="icon edit-icon fi flaticon-edit nutrition-fact-edit-icon-nutrition" />
                Edit Nutrition
              </Button>
            )}
          </div>
          {nutritionalInfo?.nutritions?.attributeStatus && nutritionalInfo?.nutritions?.authorRole === userType && (
            <div className="nutrition-row dv-note edit-button-wrap">
              <div id="" className={nutritionalPanelStatusApplier(nutritionalInfo)}>
                {attributeEditorDetails(
                  userType,
                  nutritionalInfo?.nutritions?.attributeStatus || null,
                  nutritionalInfo?.nutritions
                )}
              </div>
              {nutritionalInfo?.nutritions?.attributeStatus === REJECTED && (
                <div
                  id="nutritional-column-overview-data-footer-bar-edited-meta-data-reason"
                  className="nutrition-fact-edit-by-rejected nutrition-fact-edit-by-rejected-reason"
                >
                  {i18n.t('infoColumn.reason')}: <span>{nutritionalInfo?.nutritions?.comment}</span>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div id="nutritional-column-ingredients" className="ingredients">
        <div id="nutritional-column-ingredients-info" className="info-row">
          <div id="nutritional-column-ingredients-block" className="data-block no-highlight">
            <div id="nutritional-column-ingredients-label" className="label">
              {t('nutritionalColumn.ingredients')}
            </div>
            <div id="nutritional-column-ingredients-value" className="data description">
              {nutritionalInfo?.ingredients}
            </div>
          </div>
        </div>
      </div>

      <Certifications
        nutritionalInfo={nutritionalInfo}
        userType={userType}
        associatedUsers={associatedUsers}
        supc={supc}
        userId={userId}
        permissions={permissions}
      />
      <RejectModal
        id="nutritional-info-column-overview-reject-modal"
        modalVisibility={nutritionlRejectModal}
        setRejectVisibility={setNutritionlRejectModal}
        rejectFunction={(comment) => {
          setNutritionalInformation(nutritionalInfo?.nutritions?.previousValue);
          handleNutritionalInfoSave(
            nutritionalInformation,
            REJECTED,
            comment,
            nutritionalInfo?.nutritions?.previousValue
          );
        }}
        rejectAttribute={i18n.t('nutritionalColumn.nutrtionFacts')}
      />
    </div>
  );
}

export default NutritionalColumn;
