import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Resizer from 'react-image-file-resizer';
import { Button, Icon, Modal, Upload, Tabs, notification } from 'antd';
import { updateStagingAttribute, updateItem } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { FILENAME_DELIMITER } from 'constants/Constants';
import { getMimeType, isValidFileName, isValidFileType } from 'utils/FileUploadValidator';
import { Video } from 'video-metadata-thumbnails';
import i18n from '../../_i18n';
import AppLoader from '../AppLoader';
import Notification from '../Notification';
import { LIFESTYLE_IMAGE, PRODUCT_IMAGE, VIDEO } from '../../enums/AttributeType';
import { postRequest, putS3Request } from '../../_http';
import { getFileName } from '../../utils/Utils';
import { APPROVED, PENDING } from '../../enums/ApprovalStatus';

const CONTENT_SERVER_URL = process.env.REACT_APP_CONTENT_SERVER_URL;

const resizeFile = (file) =>
  new Promise((resolve) => {
    const format = file.type === 'image/png' ? 'png' : 'jpeg';
    Resizer.imageFileResizer(
      file,
      2000,
      2000,
      format,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

const setImageName = (otherImagesList, imageSupc, imageExtension, imageNo) => {
  let size = otherImagesList ? otherImagesList.length + imageNo : 0 + imageNo;
  let imageName = `${imageSupc}x${++size}${imageExtension}`;
  let imageIds;
  if (otherImagesList) {
    imageIds = otherImagesList.map((image) => {
      return image?.value?.imageID;
    });
    while (imageIds.includes(imageName)) {
      imageName = `${imageSupc}x${++size}${imageExtension}`;
    }
  }
  return imageName;
};

export const upload = async (file, uploadType, approvable, userId, userRole, permissions) => {
  let filenameWithPciPrefix = getFileName(uploadType, file?.name, permissions[uploadType].approvable);
  const fileToSign = { fileName: filenameWithPciPrefix, contentType: file?.type, operation: 'putObject' };
  const response = await postRequest('/staging/signed-url', fileToSign, {});
  const result = response?.data;
  await putS3Request(result?.url, file);
  const contentUrl = `${CONTENT_SERVER_URL}/${result?.fileName}`;
  const videoThumbnail = `${CONTENT_SERVER_URL}/${result?.fileName
    ?.replace('videos', 'videos/thumbnails')
    .replace('mp4', 'jpg')}`;

  const split = result?.fileName?.split('/');
  const fileName = split[split?.length - 1];
  if (uploadType === LIFESTYLE_IMAGE || uploadType === PRODUCT_IMAGE || uploadType === VIDEO) {
    await postRequest('/staging/invalidating-image', {}, { path: `/${filenameWithPciPrefix}` });
  }
  //invalidate video thumbnail
  if (uploadType === VIDEO) {
    let fileThumbnail = filenameWithPciPrefix?.replace('videos', 'videos/thumbnails').replace('mp4', 'jpg');
    await postRequest('/staging/invalidating-image', {}, { path: `/${fileThumbnail}` });
  }

  let toSave;
  switch (uploadType) {
    case LIFESTYLE_IMAGE:
      toSave = {
        imageID: fileName,
        url: contentUrl,
      };
      return toSave;
    case VIDEO:
      toSave = {
        videoID: fileName,
        url: contentUrl,
        thumbnail: videoThumbnail,
      };
      return {
        identifier: fileName,
        attributeType: uploadType,
        value: toSave,
        previousValue: null,
        attributeStatus: approvable ? APPROVED : PENDING,
        comment: null,
        author: userId,
        timestamp: null,
        authorRole: userRole,
      };
    case PRODUCT_IMAGE:
      toSave = {
        imageID: fileName,
        url: contentUrl,
      };
      return {
        identifier: fileName,
        attributeType: uploadType,
        value: toSave,
        previousValue: null,
        attributeStatus: approvable ? APPROVED : PENDING,
        comment: null,
        author: userId,
        timestamp: null,
        authorRole: userRole,
      };
  }

  return {
    attributeType: uploadType,
    value: toSave,
    attributeStatus: approvable ? APPROVED : PENDING,
    comment: null,
    author: userId,
    timestamp: null,
    authorRole: userRole,
  };
};

const uploadFileAsync = async (fileList, uploadType, userId, userRole, approvable, setCurrentImage, permissions) => {
  try {
    let saveItem;
    if (uploadType === LIFESTYLE_IMAGE) {
      saveItem = await upload(fileList[0], uploadType, approvable, userId, userRole, permissions);
      setCurrentImage({
        attributeType: uploadType,
        value: saveItem,
        attributeStatus: approvable ? APPROVED : PENDING,
        comment: null,
        author: userId,
        timestamp: null,
        authorRole: userRole,
      });
      return {
        attributeType: uploadType,
        value: saveItem,
        attributeStatus: approvable ? APPROVED : PENDING,
        comment: null,
        author: userId,
        timestamp: null,
        authorRole: userRole,
      };
    } else {
      saveItem = [];
      for (const file of fileList) {
        saveItem.push(await upload(file, uploadType, approvable, userId, userRole, permissions));
      }
      setCurrentImage({
        attributeType: uploadType,
        value: saveItem,
        attributeStatus: approvable ? APPROVED : PENDING,
        comment: null,
        author: userId,
        timestamp: null,
        authorRole: userRole,
      });
      return {
        attributeType: uploadType,
        value: saveItem,
        attributeStatus: approvable ? APPROVED : PENDING,
        comment: null,
        author: userId,
        timestamp: null,
        authorRole: userRole,
      };
    }
  } catch (error) {}
};

function checkFileNameValidity(file, thumbnailFile, fileListToUpload, thumbnailListToUpload, visibleSetter, failure) {
  if (isValidFileName(file?.name)) {
    fileListToUpload.push(file);
    thumbnailListToUpload.push(thumbnailFile);
    return failure;
  } else {
    visibleSetter(false);
    Notification({
      message: i18n.t('notification.invalidFileName'),
      description: i18n.t('notification.invalidFileNameDescription'),
      className: 'error',
      isClosable: true,
    });
    failure = true;
    return failure;
  }
}

const checkImageWH = (
  file,
  minWidth,
  minHeight,
  mediaType,
  isProductImageResolution,
  lifestyleFileList,
  imageFileList,
  setLifestyleFileList,
  setImageFileList,
  setIsResizing
) => {
  let reader = new FileReader();
  reader.onload = async (e) => {
    let image = new Image();
    image.src = e.target.result;
    await image.decode();
    // now we can:
    const width = image.width;
    const height = image.height;
    if (checkImageQuality(minWidth, width, minHeight, height)) {
      isProductImageResolution.current = false;
      Notification({
        message: i18n.t('notification.imageQualityError'),
        description: `${i18n.t('notification.imageQualityErrorPrimaryDescription')}${minWidth}${i18n.t(
          'notification.imageQualityErrorSecondaryDescription'
        )}${minHeight}.`,
        className: 'error',
        isClosable: true,
        duration: 0,
      });
    } else if (width > 2000 || height > 2000) {
      const resizedImage = await resizeFile(checkIsLifestyleImage(mediaType, file, file.originFileObj));
      let index = setIndex(mediaType, lifestyleFileList, imageFileList, file);
      let updatedArray = checkIsLifestyleImage(mediaType, lifestyleFileList, imageFileList);
      resizedImage['uid'] = file.uid;
      let updatedObj =
        mediaType === LIFESTYLE_IMAGE
          ? resizedImage
          : {
              lastModified: resizedImage.lastModified,
              lastModifiedDate: resizedImage.lastModifiedDate,
              name: resizedImage?.name,
              size: resizedImage.size,
              type: resizedImage.type,
              percent: 0,
              uid: file.uid,
              originFileObj: resizedImage,
            };
      updatedArray[index] = updatedObj;
      mediaType === LIFESTYLE_IMAGE ? setLifestyleFileList(updatedArray) : setImageFileList(updatedArray);
      setIsResizing(false);
    } else {
      setIsResizing(false);
      isProductImageResolution.current = true && isProductImageResolution.current;
    }
  };
  reader.readAsDataURL(checkIsLifestyleImage(mediaType, file, file.originFileObj));
};

const checkVideoWH = (file, minWidth, minHeight, isVideoResolution) => {
  let reader = new FileReader();
  reader.onload = async (e) => {
    let src = e.target.result;
    let video = document.createElement('video');
    video.onloadedmetadata = () => {
      if ((minWidth && video.videoWidth < minWidth) || (minHeight && video.videoWidth < minHeight)) {
        isVideoResolution.current = false;
        Notification({
          message: i18n.t('notification.videoQualityError'),
          description: `${i18n.t('notification.videoQualityErrorPrimaryDescription')}${minWidth}${i18n.t(
            'notification.videoQualityErrorSecondaryDescription'
          )}${minHeight}.`,
          className: 'error',
          isClosable: true,
          duration: 0,
        });
      } else if (file.size / 1024 / 1024 > 50) {
        Notification({
          message: i18n.t('notification.videoSizeError'),
          description: i18n.t('notification.videoSizeErrorDescription'),
          className: 'error',
          isClosable: true,
          duration: 0,
        });
      } else {
        isVideoResolution.current = true && isVideoResolution.current;
      }
    };
    video.src = src;
  };
  reader.readAsDataURL(file.originFileObj);
};

export function getListToProcess(uploadType, lifestyleFileList, imageFileList, videoFileList) {
  if (uploadType === LIFESTYLE_IMAGE) {
    return [...lifestyleFileList.slice(-1)];
  } else if (uploadType === PRODUCT_IMAGE) {
    return [...imageFileList];
  } else {
    return [...videoFileList];
  }
}

export function checkIsLifestyleImage(mediaType, LifestyleImage, notLifestyleImage) {
  return mediaType === LIFESTYLE_IMAGE ? LifestyleImage : notLifestyleImage;
}

function setIndex(mediaType, lifestyleFileList, imageFileList, file) {
  return mediaType === LIFESTYLE_IMAGE
    ? lifestyleFileList.reduce((i, item, index) => (item?.name === file?.name ? index : i), -1)
    : imageFileList.reduce((i, item, index) => (item?.name === file?.originFileObj?.name ? index : i), -1);
}

export function checkImageQuality(minWidth, width, minHeight, height) {
  return (minWidth && width < minWidth) || (minHeight && height < minHeight);
}

export function setFileForNotLifestyle(uploadType, file) {
  if (uploadType !== LIFESTYLE_IMAGE) {
    file = file.originFileObj;
  }
  return file;
}

export function generateFileName(uploadType, existing, existingVideos, supc, extension, i) {
  if (uploadType === PRODUCT_IMAGE) {
    return setImageName(existing, supc, extension, i);
  } else if (uploadType === VIDEO) {
    return setImageName(existingVideos, supc, extension, i);
  } else {
    return supc + extension;
  }
}

const lifeStyleInResolution = (
  lifestyleFileList,
  isProductImageResolution,
  setInResolutionLimit,
  imageFileList,
  setLifestyleFileList,
  setImageFileList,
  setIsResizing
) => {
  if (lifestyleFileList.length > 0) {
    checkImageWH(
      lifestyleFileList[lifestyleFileList.length - 1],
      900,
      900,
      LIFESTYLE_IMAGE,
      isProductImageResolution,
      lifestyleFileList,
      imageFileList,
      setLifestyleFileList,
      setImageFileList,
      setIsResizing
    );
    getLifeStyleBadResolution(isProductImageResolution, setInResolutionLimit);
  }
  setInResolutionLimit(true);
};

const productImagesInResolution = (
  imageFileList,
  isProductImageResolution,
  setInResolutionLimit,
  lifestyleFileList,
  setLifestyleFileList,
  setImageFileList,
  setIsResizing
) => {
  if (imageFileList.length > 0) {
    for (const file of imageFileList) {
      checkImageWH(
        file,
        900,
        900,
        PRODUCT_IMAGE,
        isProductImageResolution,
        lifestyleFileList,
        imageFileList,
        setLifestyleFileList,
        setImageFileList,
        setIsResizing
      );
      getProducImagesBadResolution(isProductImageResolution, setInResolutionLimit);
    }
    setInResolutionLimit(true);
  }
  setInResolutionLimit(true);
};

const videosInResolution = (videoFileList, isVideoResolution, setInResolutionLimit) => {
  if (videoFileList.length > 0) {
    for (const file of videoFileList) {
      checkVideoWH(file, 900, 900, isVideoResolution);
      getVideoBadResolution(isVideoResolution, setInResolutionLimit);
    }
    setInResolutionLimit(true);
  }
  setInResolutionLimit(true);
};

const videosInsize = (videoFileList) => {
  if (videoFileList.length > 0) {
    for (const file of videoFileList) {
      if (file?.size / 1024 / 1024 > 50) {
        return false;
      }
    }
    return true;
  }
  return true;
};

const getProducImagesBadResolution = _.debounce((isProductImageResolution, setInResolutionLimit) => {
  if (!isProductImageResolution.current) {
    setInResolutionLimit(false);
  }
}, 300);

const getLifeStyleBadResolution = _.debounce((isProductImageResolution, setInResolutionLimit) => {
  if (!isProductImageResolution.current) {
    setInResolutionLimit(false);
  } else {
    setInResolutionLimit(true);
  }
}, 300);

const getVideoBadResolution = _.debounce((isVideoResolution, setInResolutionLimit) => {
  if (!isVideoResolution.current) {
    setInResolutionLimit(false);
  }
}, 300);

function getIsFetching(itemFetching, uploadFetching, uploading) {
  return itemFetching || uploadFetching || uploading;
}

export function getVideoSizeLimit(videoFileList) {
  return !videosInsize(videoFileList) ? false : true;
}

export function getIsDisabled(
  lifestyleFileList,
  imageFileList,
  videoFileList,
  inSizeLimit,
  inResolutionLimit,
  isResizing
) {
  return (
    (lifestyleFileList?.length === 0 && imageFileList?.length === 0 && videoFileList?.length === 0) ||
    !inSizeLimit ||
    !inResolutionLimit ||
    isResizing
  );
}

export async function thumbnailListUpload(thumbnailListToUpload, uploadType, approvable) {
  await thumbnailListToUpload;
  for (const file of thumbnailListToUpload) {
    let thumbnailUrl;
    thumbnailUrl = getFileName(uploadType, file?.name, approvable);
    const fileToSign = { fileName: thumbnailUrl, contentType: file?.type, operation: 'putObject' };
    const response = await postRequest('/staging/signed-url', fileToSign, {});
    const result = response?.data;
    await putS3Request(result?.url, file);
  }
}

export async function processVideoThumbnail(blob, videoScreenshotTime, existingVideos, supc, extension, i) {
  const video = new Video(blob);
  let thumbnailBlobs;
  thumbnailBlobs = await video.getThumbnails({
    quality: 0.9,
    start: videoScreenshotTime,
    end: videoScreenshotTime,
  });
  return blobToFile(thumbnailBlobs[0]?.blob, setImageName(existingVideos, supc, extension, i).split('.')[0] + '.jpg');
}

export function checkValidFile(file) {
  const isValidType = isValidFileType(file?.type);
  return file && isValidType && file?.name?.includes(FILENAME_DELIMITER);
}

export function checkImageFileList(imageFileList, array) {
  if (array.length > imageFileList.length) {
    return array;
  } else {
    return imageFileList;
  }
}

export function checkVideoFileList(videoFileList, array) {
  if (array.length > videoFileList.length) {
    return array;
  } else {
    return videoFileList;
  }
}

export const blobToFile = (theBlob, fileName) => {
  theBlob.name = fileName;
  return theBlob;
};

export const checkFileNameSplit = (file) => {
  return file?.name?.split(FILENAME_DELIMITER);
};

export const uploadThumbnail = (thumbnailListToUpload, _uploadType, _approvable) => {
  if (_uploadType === 'VIDEO') {
    thumbnailListUpload(thumbnailListToUpload, _uploadType, _approvable);
  }
};

export const generateThumbnailFile = async (_uploadType, blob, _existingVideos, _supc, extension, i) => {
  let videoThumbnailFile;
  if (_uploadType === 'VIDEO') {
    const videoScreenshotTime = 1;
    videoThumbnailFile = await processVideoThumbnail(blob, videoScreenshotTime, _existingVideos, _supc, extension, i);
  }
  return videoThumbnailFile;
};

export const checkItemCatalogRefresh = (updateAttributeFetching, uploadType, dispatch) => {
  if (updateAttributeFetching === false && uploadType === LIFESTYLE_IMAGE) {
    dispatch(updateItem());
  }
};

function UploadModal({ visible, loading, visibleSetter, approvable, setCurrentImage, videoEditable, permissions }) {
  const { data } = useSelector((state) => state.item);
  const [supc] = useState(data?.coreData?.supc);
  const [inSizeLimit, setInSizeLimit] = useState(true);
  const [inResolutionLimit, setInResolutionLimit] = useState(true);
  const isProductImageResolution = useRef(true);
  const isVideoResolution = useRef(true);
  const existing = data?.media?.otherImageUrls;
  const existingVideos = data?.media?.videos;

  const [lifestyleFileList, setLifestyleFileList] = useState([]);
  const [imageFileList, setImageFileList] = useState([]);
  const [videoFileList, setVideoFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [isResizing, setIsResizing] = useState(false);

  const dispatch = useDispatch();
  const { fetching: uploadFetching } = useSelector((state) => state.uploadFile);
  const { fetching: itemFetching } = useSelector((state) => state.item);
  const { fetching: updateAttributeFetching } = useSelector((state) => state.updateAttribute);
  const {
    data: { fullName: userId, userRole, associatedUsers },
  } = useSelector((state) => state.userData);

  const { Dragger } = Upload;

  const { TabPane } = Tabs;

  function callback(key) {
    console.log(key);
  }

  useEffect(() => {
    checkItemCatalogRefresh(updateAttributeFetching, uploadType, dispatch);
  }, [updateAttributeFetching]);

  const customUpload = async () => {
    let fileListToUpload = [];
    let thumbnailListToUpload = [];
    const listToProcess = getListToProcess(uploadType, lifestyleFileList, imageFileList, videoFileList);

    if (listToProcess?.length > 0) {
      let i = 0;
      let failure = false;
      for (let loopFile of listToProcess) {
        let file = loopFile;
        let thumbnailFile;

        if (checkValidFile(file)) {
          file = setFileForNotLifestyle(uploadType, file);
          // Get file extension from file name
          const splitFilename = checkFileNameSplit(file);
          const extension = FILENAME_DELIMITER.concat(splitFilename[splitFilename.length - 1]);
          // create a blob from file calling mime type injection function
          const blob = new Blob([file], { type: getMimeType(extension) });
          thumbnailFile = await generateThumbnailFile(uploadType, blob, existingVideos, supc, extension, i);
          // re-convert to a file
          file = await blobToFile(blob, generateFileName(uploadType, existing, existingVideos, supc, extension, i));

          failure = await checkFileNameValidity(
            file,
            thumbnailFile,
            fileListToUpload,
            thumbnailListToUpload,
            visibleSetter,
            failure
          );
        } else {
          Notification({
            message: i18n.t('notification.invalidFileType'),
            description: i18n.t('notification.invalidFileTypeDescription'),
            className: 'error',
            isClosable: true,
          });
          failure = true;
        }
        i = i + 1;
      }
      //call back issue is
      if (!failure) {
        setUploading(true);
        uploadThumbnail(thumbnailListToUpload, uploadType, approvable);
        const attribute = await uploadFileAsync(
          fileListToUpload,
          uploadType,
          userId,
          userRole,
          approvable,
          setCurrentImage,
          permissions
        );
        dispatch(updateStagingAttribute(supc, i18n.language, attribute, associatedUsers));

        visibleSetter(false);
        setUploading(false);
        clearFileList();
      }
    }
  };

  const clearFileList = () => {
    setLifestyleFileList([]);
    setImageFileList([]);
    setVideoFileList([]);
    notification.destroy();
  };

  const propsLifestyle = {
    multiple: false,
    onRemove: (file) => {
      setLifestyleFileList([]);
    },
    beforeUpload: (file) => {
      setUploadType(LIFESTYLE_IMAGE);
      setLifestyleFileList([...lifestyleFileList, file]);
      isProductImageResolution.current = true;
      return false;
    },
    fileList: lifestyleFileList.slice(-1),
    accept: 'image/png, image/jpeg',
  };

  const propsImages = {
    multiple: true,
    onRemove: (file) => {
      const index = imageFileList.indexOf(file);
      const newFileList = imageFileList.slice();
      newFileList.splice(index, 1);
      setImageFileList(newFileList);
      isProductImageResolution.current = true;
    },
    beforeUpload: (file) => {
      setUploadType(PRODUCT_IMAGE);
      setImageFileList([...imageFileList, file]);
      return false;
    },
    fileList: imageFileList,
    accept: 'image/png, image/jpeg',
  };

  const propsVideos = {
    multiple: true,
    onRemove: (file) => {
      const index = videoFileList.indexOf(file);
      const newFileList = videoFileList.slice();
      newFileList.splice(index, 1);
      setVideoFileList(newFileList);
      isVideoResolution.current = true;
    },
    beforeUpload: (file) => {
      setUploadType(VIDEO);
      setVideoFileList([...videoFileList, file]);
      return false;
    },
    fileList: videoFileList,
    accept: 'video/mp4',
  };

  const handleOtherImageChange = (info) => {
    setImageFileList(checkImageFileList(imageFileList, [...info.fileList]));
  };

  const handleVideoChange = (info) => {
    setVideoFileList(checkVideoFileList(videoFileList, [...info.fileList]));
  };

  useEffect(() => {
    setIsResizing(true);
    notification.destroy();
    lifeStyleInResolution(
      lifestyleFileList,
      isProductImageResolution,
      setInResolutionLimit,
      imageFileList,
      setLifestyleFileList,
      setImageFileList,
      setIsResizing
    );
  }, [lifestyleFileList]);

  useEffect(() => {
    setIsResizing(true);
    notification.destroy();
    productImagesInResolution(
      imageFileList,
      isProductImageResolution,
      setInResolutionLimit,
      lifestyleFileList,
      setLifestyleFileList,
      setImageFileList,
      setIsResizing
    );
  }, [imageFileList]);

  useEffect(() => {
    setIsResizing(false);
    notification.destroy();
    videosInResolution(videoFileList, isVideoResolution, setInResolutionLimit);
    setInSizeLimit(getVideoSizeLimit(videoFileList));
  }, [videoFileList]);

  return getIsFetching(itemFetching, uploadFetching, uploading) ? (
    <AppLoader show />
  ) : (
    <Modal
      id="upload-modal"
      className="item-popup"
      visible={visible}
      destroyOnClose={true}
      onCancel={() => {
        clearFileList();
        visibleSetter(false);
      }}
      title={[
        <div id="upload-modal-header" key="upload-modal-header" className="header">
          <i className="icon fi flaticon-upload" />
          <div id="upload-modal-title" key="upload-modal-title" className="title">
            {i18n.t('upload.uploadMedia')}
          </div>
        </div>,
      ]}
      footer={[
        <Button
          id="upload-modal-back"
          key="upload-modal-back"
          onClick={() => {
            clearFileList();
            visibleSetter(false);
          }}
        >
          {i18n.t('infoColumn.cancel')}
        </Button>,
        <Button
          id="upload-modal-submit"
          key="upload-modal-submit"
          type="primary"
          onClick={() => customUpload()}
          disabled={getIsDisabled(
            lifestyleFileList,
            imageFileList,
            videoFileList,
            inSizeLimit,
            inResolutionLimit,
            isResizing
          )}
        >
          {i18n.t('upload.upload')}
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="upload-modal-tab-1" onChange={callback} id="tabspane">
        <TabPane tab={i18n.t('upload.lifestyleImage')} key="upload-modal-tab-1" id="upload-modal-tab-1">
          <div id="upload-modal-tab-1-description" className="note">
            <i className="icon fi flaticon-information-button" /> {i18n.t('upload.imageUploadDescription')}
          </div>
          <Dragger id="upload-modal-tab-1-dragger" {...propsLifestyle}>
            <p id="upload-modal-tab-1-dragger-icon" className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p id="upload-modal-tab-1-dragger-text" className="ant-upload-text">
              {i18n.t('fileImport.fileUpload')}
            </p>
            <p id="upload-modal-tab-1-dragger-hint" className="ant-upload-hint">
              JPEG / PNG {i18n.t('fileImport.minResolution')}: 900 x 900
            </p>
          </Dragger>
        </TabPane>
        <TabPane tab={i18n.t('upload.productImage')} key="upload-modal-tab-2" id="upload-modal-tab-2">
          <div id="upload-modal-tab-2-description" className="note">
            <i className="icon fi flaticon-information-button" /> {i18n.t('upload.imageUploadDescription')}
          </div>
          <Dragger id="upload-modal-tab-2-dragger" {...propsImages} onChange={handleOtherImageChange}>
            <p id="upload-modal-tab-2-dragger-icon" className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p id="upload-modal-tab-2-dragger-text" className="ant-upload-text">
              {i18n.t('fileImport.fileUpload')}
            </p>
            <p id="upload-modal-tab-2-dragger-hint" className="ant-upload-hint">
              JPEG / PNG {i18n.t('fileImport.minResolution')}: 900 x 900
            </p>
          </Dragger>
        </TabPane>
        {videoEditable && (
          <TabPane tab="Video" key="3" id="upload-modal-tab-3">
            <div id="upload-modal-tab-3-description" className="note">
              <i className="icon fi flaticon-information-button" /> All videos have to be of maximum size of 50mb and
              should be in MP4 format.
            </div>
            <Dragger id="upload-modal-tab-3-dragger" {...propsVideos} onChange={handleVideoChange}>
              <p id="upload-modal-tab-3-dragger-icon" className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p id="upload-modal-tab-3-dragger-text" className="ant-upload-text">
                {i18n.t('fileImport.fileUpload')}
              </p>
              <p id="upload-modal-tab-3-dragger-hint" className="ant-upload-hint">
                MP4 {i18n.t('fileImport.maxSize')} : 50mb
              </p>
            </Dragger>
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
}

export default UploadModal;
