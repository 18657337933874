import React, { useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import FileSaver from 'file-saver';
import { Button, Icon, message, Table, Upload, Tooltip, notification } from 'antd';
import Excel from 'exceljs';
import { Importer } from 'xlsx-import/lib/Importer';
import { bulkUpload } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import Notification from '../components/Notification';
import { getSampleDownload, getSampleDownloadHeaders } from '../constants/SampleDownload';
import i18n from '../_i18n';

import {
  ABOUT_BRAND,
  CERTIFICATION,
  LIFESTYLE_IMAGE,
  SPECIFICATION_SHEET,
  MSDS_SHEET,
  PRODUCT_IMAGE,
  RECIPES,
  UNABBREVIATED_DESCRIPTION,
  VIDEO,
  PRODUCT_DESCRIPTOR,
  PACKAGING_INFORMATION,
  SIZE_AND_SHAPE_OF_PRODUCT,
  YIELD_OR_SERVING_SIZE,
  QUALITY_AND_FORMAT,
  PREPARATION_AND_COOKING_INSTRUCTIONS,
  STORAGE_AND_USAGE,
  HANDLING_INSTRUCTIONS,
  ADDITIONAL_PRODUCT_INFORMATION,
  MARKETING_STATEMENTS,
  CULINARY_APPLICATIONS,
  FABFieldsUpdatableKeys,
} from '../enums/AttributeType';
import { COMMA_DELIMETER, SLASH_DELIMETER } from '../constants/Constants';
import AppLoader from '../components/AppLoader';
import attributeManagement from '../utils/attributeManagement';
import { APPROVED, PENDING } from '../enums/ApprovalStatus';
import { MDM, MARKETING, CATMAN } from 'enums/UserType';
import { FABFieldUploadModification } from 'utils/FABFieldModification';

function getAttributeJson(attributeType, attributeValue, isSyscoAssociate, field, userId, userRole, condition) {
  if (condition) {
    return {
      attributeType: attributeType,
      value: attributeValue,
      attributeStatus: isSyscoAssociate || field?.approvable ? APPROVED : PENDING,
      comment: null,
      author: userId,
      timestamp: null,
      authorRole: userRole,
    };
  }
  return null;
}

const getAttributeJsonCondition = (editable, override, length) => {
  return editable && (override || length > 0);
};

const generateAttributes = (item, userId, userRole, override) => {
  const attributes = [];
  const syscoAssociates = [MDM, MARKETING, CATMAN];
  const isSyscoAssociate = syscoAssociates.includes(userRole);

  const fields = attributeManagement(userRole, true); // The enrichable check should be handled by the backend

  const uad = getAttributeJson(
    UNABBREVIATED_DESCRIPTION,
    item?.description,
    isSyscoAssociate,
    fields.UNABBREVIATED_DESCRIPTION,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.UNABBREVIATED_DESCRIPTION?.editable, override, item?.description?.length)
  );
  attributes.push(uad);

  const lifestyleImage = getAttributeJson(
    LIFESTYLE_IMAGE,
    item?.lifestyleImage,
    isSyscoAssociate,
    fields.LIFESTYLE_IMAGE,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.LIFESTYLE_IMAGE?.editable, override, item?.lifestyleImage?.length)
  );

  attributes.push(lifestyleImage);

  const specSheet = getAttributeJson(
    SPECIFICATION_SHEET,
    item?.specificationSheet,
    isSyscoAssociate,
    fields.SPECIFICATION_SHEET,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.SPECIFICATION_SHEET?.editable, override, item?.specificationSheet?.length)
  );

  attributes.push(specSheet);

  const msds = getAttributeJson(
    MSDS_SHEET,
    item?.msdsSheet,
    isSyscoAssociate,
    fields.MSDS_SHEET,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.MSDS_SHEET?.editable, override, item?.msdsSheet?.length)
  );

  attributes.push(msds);

  const certification = getAttributeJson(
    CERTIFICATION,
    item?.certifications,
    isSyscoAssociate,
    fields.CERTIFICATION,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.CERTIFICATION?.editable, override, item?.certifications?.length)
  );

  attributes.push(certification);

  const productImage = getAttributeJson(
    PRODUCT_IMAGE,
    item?.productImages,
    isSyscoAssociate,
    fields.PRODUCT_IMAGE,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.PRODUCT_IMAGE?.editable, override, item?.productImages?.length)
  );

  attributes.push(productImage);

  const video = getAttributeJson(
    VIDEO,
    item?.videos,
    isSyscoAssociate,
    fields.VIDEO,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.VIDEO?.editable, override, item?.videos?.length)
  );

  attributes.push(video);

  const recipes = getAttributeJson(
    RECIPES,
    item?.recipes,
    isSyscoAssociate,
    fields.RECIPES,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.RECIPES?.editable, override, item?.recipes?.length)
  );

  attributes.push(recipes);

  const productDescriptor = getAttributeJson(
    PRODUCT_DESCRIPTOR,
    item?.productDescriptor,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.productDescriptor?.length)
  );

  attributes.push(productDescriptor);

  const packagingInformation = getAttributeJson(
    PACKAGING_INFORMATION,
    item?.packagingInformation,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.packagingInformation?.length)
  );

  attributes.push(packagingInformation);

  const sizeAndShapeOfProduct = getAttributeJson(
    SIZE_AND_SHAPE_OF_PRODUCT,
    item?.sizeAndShapeOfProduct,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.sizeAndShapeOfProduct?.length)
  );

  attributes.push(sizeAndShapeOfProduct);

  const yieldOrServingSize = getAttributeJson(
    YIELD_OR_SERVING_SIZE,
    item?.yieldOrServingSize,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.yieldOrServingSize?.length)
  );

  attributes.push(yieldOrServingSize);

  const qualityAndFormat = getAttributeJson(
    QUALITY_AND_FORMAT,
    item?.qualityAndFormat,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.qualityAndFormat?.length)
  );

  attributes.push(qualityAndFormat);

  const preparationAndCookingInstructions = getAttributeJson(
    PREPARATION_AND_COOKING_INSTRUCTIONS,
    item?.preparationAndCookingInstructions,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(
      fields?.FEATURES_AND_BENEFITS?.editable,
      override,
      item?.preparationAndCookingInstructions?.length
    )
  );

  attributes.push(preparationAndCookingInstructions);

  const storageAndUsage = getAttributeJson(
    STORAGE_AND_USAGE,
    item?.storageAndUsage,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.storageAndUsage?.length)
  );

  attributes.push(storageAndUsage);

  const handlingInstructions = getAttributeJson(
    HANDLING_INSTRUCTIONS,
    item?.handlingInstructions,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.handlingInstructions?.length)
  );

  attributes.push(handlingInstructions);

  const additionalProductInformation = getAttributeJson(
    ADDITIONAL_PRODUCT_INFORMATION,
    item?.additionalProductInformation,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(
      fields?.FEATURES_AND_BENEFITS?.editable,
      override,
      item?.additionalProductInformation?.length
    )
  );

  attributes.push(additionalProductInformation);

  const marketingStatements = getAttributeJson(
    MARKETING_STATEMENTS,
    item?.marketingStatements,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.marketingStatements?.length)
  );

  attributes.push(marketingStatements);

  const culinaryApplications = getAttributeJson(
    CULINARY_APPLICATIONS,
    item?.culinaryApplications,
    isSyscoAssociate,
    fields.FEATURES_AND_BENEFITS,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.FEATURES_AND_BENEFITS?.editable, override, item?.culinaryApplications?.length)
  );

  attributes.push(culinaryApplications);

  const brand = getAttributeJson(
    ABOUT_BRAND,
    item?.aboutBrand,
    isSyscoAssociate,
    fields.ABOUT_BRAND,
    userId,
    userRole,
    getAttributeJsonCondition(fields?.ABOUT_BRAND?.editable, override, item?.aboutBrand?.length)
  );

  attributes.push(brand);

  return attributes?.filter((attr) => attr);
};

const validateFabs = (item) => {
  if (checkBulletPoints(item?.packagingInformation)) {
    fireFileErrorNotification(i18n.t('activityLog.packagingInformation') + i18n.t('notification.asterixInFnB'));
    return false;
  }

  if (checkBulletPoints(item?.sizeAndShapeOfProduct)) {
    fireFileErrorNotification(i18n.t('activityLog.sizeAndShapeOfProduct') + i18n.t('notification.asterixInFnB'));
    return false;
  }

  if (checkBulletPoints(item?.yieldOrServingSize)) {
    fireFileErrorNotification(i18n.t('activityLog.yieldOrServingSize') + i18n.t('notification.asterixInFnB'));
    return false;
  }

  if (checkBulletPoints(item?.qualityAndFormat)) {
    fireFileErrorNotification(i18n.t('activityLog.qualityAndFormat') + i18n.t('notification.asterixInFnB'));
    return false;
  }

  if (checkBulletPoints(item?.preparationAndCookingInstructions)) {
    fireFileErrorNotification(
      i18n.t('activityLog.preparationAndCookingInstructions') + i18n.t('notification.asterixInFnB')
    );
    return false;
  }

  if (checkBulletPoints(item?.storageAndUsage)) {
    fireFileErrorNotification(i18n.t('activityLog.storageAndUsage') + i18n.t('notification.asterixInFnB'));
    return false;
  }

  if (checkBulletPoints(item?.handlingInstructions)) {
    fireFileErrorNotification(i18n.t('activityLog.handlingInstructions') + i18n.t('notification.asterixInFnB'));
    return false;
  }

  if (checkBulletPoints(item?.additionalProductInformation)) {
    fireFileErrorNotification(i18n.t('activityLog.additionalProductInformation') + i18n.t('notification.asterixInFnB'));
    return false;
  }

  if (checkBulletPoints(item?.marketingStatements)) {
    fireFileErrorNotification(i18n.t('activityLog.marketingStatements') + i18n.t('notification.asterixInFnB'));
    return false;
  }

  if (checkBulletPoints(item?.culinaryApplications)) {
    fireFileErrorNotification(i18n.t('activityLog.culinaryApplications') + i18n.t('notification.asterixInFnB'));
    return false;
  }
  return true;
};

const validItem = (item) => {
  if (!item.supc) {
    fireFileErrorNotification(i18n.t('notification.supcNull'));
    return false;
  }

  if (!validateFabs(item)) {
    return false;
  }

  if (item?.lifestyleImage && !isValidUrl(item.lifestyleImage, LIFESTYLE_IMAGE)) {
    fireFileErrorNotification(i18n.t('notification.invalidUrlPattern') + LIFESTYLE_IMAGE);
    return false;
  }

  if (item?.specificationSheet) {
    checkValidUrlList(item?.specificationSheet, SPECIFICATION_SHEET);
  }

  if (item?.msdsSheet) {
    checkValidUrlList(item?.msdsSheet, MSDS_SHEET);
  }

  if (item?.certifications) {
    checkValidUrlList(item?.certifications, CERTIFICATION);
  }
  if (item?.videos) {
    checkValidUrlList(item?.videos, VIDEO);
  }
  if (item?.recipes) {
    checkValidUrlList(item?.recipes, RECIPES);
  }
  // Do the same for other file upload types
  return true;
};

const checkValidUrlList = (urlList, attributeName) => {
  const urls = urlList?.split(COMMA_DELIMETER);
  for (const url of urls) {
    if (!isValidUrl(url, attributeName)) {
      fireFileErrorNotification(i18n.t('notification.invalidUrlPattern') + attributeName);
      return false;
    }
  }
};

const fireFileErrorNotification = (description) => {
  Notification({
    message: i18n.t('notification.invalidaDataInFile'),
    description: description,
    className: 'error',
    isClosable: true,
    duration: 10,
  });
};

export const isValidUrl = (url, uploadType) => {
  const validExtensions = ['pdf', 'jpg', 'png', 'mp4', 'jpeg'];
  const urlParts = url?.split(SLASH_DELIMETER);
  if (urlParts?.length > 0) {
    const fullName = urlParts[urlParts.length - 1];
    if (fullName?.length > 0) {
      const fileExtension = fullName.split('.').pop();
      return validExtensions.includes(fileExtension.toLowerCase());
    }
  } else return false;
};

export const addLeadingZeros = (supc) => {
  if (/^\d+$/.test(supc)) {
    while (supc.length < 7) supc = '0' + supc;
  }
  return supc;
};

export const checkBulletPoints = (fabs) => {
  return fabs !== null && fabs !== '' && fabs.trim().slice(0, 1) !== '•';
};

export const selectAllRows = (uploadItems, uploadData) => {
  return uploadItems?.length !== uploadData?.length ? uploadData : [];
};

const isValidHeader = (headerRow) => {
  let counter = 0;
  for (var key in headerRow) {
    var headerName = headerRow[key];
    const correctHeader = getSampleDownloadHeaders()[counter]?.header;
    if (headerName !== correctHeader) {
      const errorMessage = `${i18n.t('fileImport.headerValidation1')} ${headerName} ${i18n.t(
        'fileImport.headerValidation2'
      )} ${counter + 1} ${i18n.t('fileImport.headerValidation3')}`;
      fireFileErrorNotification(errorMessage);
      return false;
    }
    counter++;
  }
  return true;
};

/**
 * Get item object from file and modify FAB fields as per the guard rails
 * @param  {Object} item
 * @returns Object
 */
const getValidatedModifiedItem = (item) => {
  const modifiedItem = Object.values(FABFieldsUpdatableKeys).reduce(
    (tempItem, field) => {
      if (tempItem.hasOwnProperty(field)) tempItem[field] = FABFieldUploadModification(tempItem[field]);
      return tempItem;
    },
    { ...item }
  );

  if (!validItem(modifiedItem)) {
    fireFileErrorNotification(i18n.t('notification.invalidDataWithSupc') + modifiedItem?.supc);
    return { ...modifiedItem, invalid: true };
  } else {
    return modifiedItem;
  }
};

function FileImport({ vendorNo }) {
  const [override] = useState(false);
  const [uploadItems, setUploadItems] = useState([]);
  const { Dragger } = Upload;
  const [gridScrollHeight, setGridScrollHeight] = useState('40vh');
  const [uploadData, setUploadData] = useState([]);

  const { fetching: bulkUploadFetching } = useSelector((state) => state.bulkUpload);
  const {
    data: { fullName: userId, userRole, associatedUsers },
  } = useSelector((state) => state.userData); //userType= MDM , VENDOR

  const clear = () => {
    notification.destroy();
    setUploadData([]);
    setUploadItems([]);
  };

  const generateUploadingAttributes = (items) => {
    return items?.map((item) => {
      return {
        supc: item?.supc,
        attributes: generateAttributes(item, userId, userRole, override),
      };
    });
  };

  const dispatch = useDispatch();

  const upload = () => {
    if (uploadItems?.length > 0) {
      const filtered = uploadItems.filter((i) => i.invalid).map((i) => i.supc);
      if (filtered && filtered.length > 0) {
        fireFileErrorNotification(i18n.t('notification.containsInvalidRows') + filtered.join(COMMA_DELIMETER));
      } else {
        var attributes = generateUploadingAttributes(uploadItems);
        dispatch(bulkUpload(i18n.language, false, userRole, attributes, vendorNo, associatedUsers));
        clear();
      }
    } else {
      fireFileErrorNotification(i18n.t('notification.bulkUploadFileErrorDescription'));
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: (info) => {
      const excelData = [];

      const reader = new FileReader();
      reader.onload = (e) => {
        const workbook = new Excel.Workbook();

        const dataList = new Uint8Array(e.target.result);
        workbook.xlsx.load(dataList).then(function () {
          const importer = new Importer(workbook);
          let index = 1;
          const config = {
            type: 'list',
            columns: [
              { index: index++, key: 'supc' },
              { index: index++, key: 'description' },
              { index: index++, key: 'lifestyleImage' },
              { index: index++, key: 'videos' },
              { index: index++, key: 'productDescriptor' },
              { index: index++, key: 'packagingInformation' },
              { index: index++, key: 'sizeAndShapeOfProduct' },
              { index: index++, key: 'yieldOrServingSize' },
              { index: index++, key: 'qualityAndFormat' },
              { index: index++, key: 'preparationAndCookingInstructions' },
              { index: index++, key: 'storageAndUsage' },
              { index: index++, key: 'handlingInstructions' },
              { index: index++, key: 'additionalProductInformation' },
              { index: index++, key: 'marketingStatements' },
              { index: index++, key: 'culinaryApplications' },
              { index: index++, key: 'certifications' },
              { index: index++, key: 'specificationSheet' },
              { index: index++, key: 'additionalResources' },
              { index: index++, key: 'aboutBrand' },
              { index: index++, key: 'productImages' },
              { index: index, key: 'msdsSheet' },
            ],
          };
          const data = importer.getAllItems(config);

          const headerRow = data[0];
          data.splice(0, 1);

          if (isValidHeader(headerRow)) {
            data.forEach((element, index) => {
              const item = {
                key: index + 1,
                supc: addLeadingZeros(element.supc),
                description: element.description,
                lifestyleImage: element.lifestyleImage,
                productDescriptor: element.productDescriptor,
                packagingInformation: element.packagingInformation,
                sizeAndShapeOfProduct: element.sizeAndShapeOfProduct,
                yieldOrServingSize: element.yieldOrServingSize,
                qualityAndFormat: element.qualityAndFormat,
                preparationAndCookingInstructions: element.preparationAndCookingInstructions,
                storageAndUsage: element.storageAndUsage,
                handlingInstructions: element.handlingInstructions,
                additionalProductInformation: element.additionalProductInformation,
                marketingStatements: element.marketingStatements,
                culinaryApplications: element.culinaryApplications,
                specificationSheet: element.specificationSheet,
                recipes: element.additionalResources,
                videos: element.videos,
                certifications: element.certifications,
                aboutBrand: element.aboutBrand,
                productImages: element.productImages,
                msdsSheet: element.msdsSheet,
              };
              excelData.push(getValidatedModifiedItem(item));
            });
            setUploadData([]); //clear array
            setUploadData([...excelData]);
          }
        });
      };
      reader.readAsArrayBuffer(info);
      return false;
    },
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const onResize = (width, height) => {
    setGridScrollHeight(height - 350);
  };

  const sendWorkbook = () => {
    const workbook = new Excel.Workbook();

    const worksheet = workbook.addWorksheet('My Sheet');
    worksheet.columns = getSampleDownloadHeaders();

    worksheet.addRow(getSampleDownload());

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, 'sample_item.xlsx');
    });
  };

  const columns = [
    {
      title: i18n.t('fileImport.columnSupc'),
      dataIndex: 'supc',
      key: 'supc',
      className: 'supc-col',
      width: '8.5rem',
      fixed: 'left',
    },
    {
      title: () => (
        <Tooltip title={i18n.t('fileImport.columnDescription')}>{i18n.t('fileImport.columnDescription')}</Tooltip>
      ),
      dataIndex: 'description',
      key: 'description',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (columnDescription) => (
        <Tooltip placement="topLeft" title={columnDescription}>
          {columnDescription}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('fileImport.lifestyleImageURL')}>{i18n.t('fileImport.lifestyleImageURL')}</Tooltip>
      ),
      dataIndex: 'lifestyleImage',
      key: 'lifestyleImage',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (lifestyleImageURL) => (
        <Tooltip placement="topLeft" title={lifestyleImageURL}>
          {lifestyleImageURL}
        </Tooltip>
      ),
    },
    {
      title: () => <Tooltip title={i18n.t('fileImport.videos')}>{i18n.t('fileImport.videos')}</Tooltip>,
      dataIndex: 'videos',
      key: 'videos',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (videos) => (
        <Tooltip placement="topLeft" title={videos}>
          {videos}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.productDescriptor')}>{i18n.t('infoColumn.productDescriptor')}</Tooltip>
      ),
      dataIndex: 'productDescriptor',
      key: 'productDescriptor',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (productDescriptor) => (
        <Tooltip placement="topLeft" title={productDescriptor}>
          {productDescriptor}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.packagingInformation')}>{i18n.t('infoColumn.packagingInformation')}</Tooltip>
      ),
      dataIndex: 'packagingInformation',
      key: 'packagingInformation',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (packagingInformation) => (
        <Tooltip placement="topLeft" title={packagingInformation}>
          {packagingInformation}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.sizeAndShapeOfProduct')}>
          {i18n.t('infoColumn.sizeAndShapeOfProduct')}
        </Tooltip>
      ),
      dataIndex: 'sizeAndShapeOfProduct',
      key: 'sizeAndShapeOfProduct',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (sizeAndShapeOfProduct) => (
        <Tooltip placement="topLeft" title={sizeAndShapeOfProduct}>
          {sizeAndShapeOfProduct}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.yieldOrServingSize')}>{i18n.t('infoColumn.yieldOrServingSize')}</Tooltip>
      ),
      dataIndex: 'yieldOrServingSize',
      key: 'yieldOrServingSize',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (yieldOrServingSize) => (
        <Tooltip placement="topLeft" title={yieldOrServingSize}>
          {yieldOrServingSize}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.qualityAndFormat')}>{i18n.t('infoColumn.qualityAndFormat')}</Tooltip>
      ),
      dataIndex: 'qualityAndFormat',
      key: 'qualityAndFormat',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (qualityAndFormat) => (
        <Tooltip placement="topLeft" title={qualityAndFormat}>
          {qualityAndFormat}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.preparationAndCookingInstructions')}>
          {i18n.t('infoColumn.preparationAndCookingInstructions')}
        </Tooltip>
      ),
      dataIndex: 'preparationAndCookingInstructions',
      key: 'preparationAndCookingInstructions',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (preparationAndCookingInstructions) => (
        <Tooltip placement="topLeft" title={preparationAndCookingInstructions}>
          {preparationAndCookingInstructions}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.storageAndUsage')}>{i18n.t('infoColumn.storageAndUsage')}</Tooltip>
      ),
      dataIndex: 'storageAndUsage',
      key: 'storageAndUsage',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (storageAndUsage) => (
        <Tooltip placement="topLeft" title={storageAndUsage}>
          {storageAndUsage}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.handlingInstructions')}>{i18n.t('infoColumn.handlingInstructions')}</Tooltip>
      ),
      dataIndex: 'handlingInstructions',
      key: 'handlingInstructions',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (handlingInstructions) => (
        <Tooltip placement="topLeft" title={handlingInstructions}>
          {handlingInstructions}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.additionalProductInformation')}>
          {i18n.t('infoColumn.additionalProductInformation')}
        </Tooltip>
      ),
      dataIndex: 'additionalProductInformation',
      key: 'additionalProductInformation',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (additionalProductInformation) => (
        <Tooltip placement="topLeft" title={additionalProductInformation}>
          {additionalProductInformation}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.marketingStatements')}>{i18n.t('infoColumn.marketingStatements')}</Tooltip>
      ),
      dataIndex: 'marketingStatements',
      key: 'marketingStatements',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (marketingStatements) => (
        <Tooltip placement="topLeft" title={marketingStatements}>
          {marketingStatements}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('infoColumn.culinaryApplications')}>{i18n.t('infoColumn.culinaryApplications')}</Tooltip>
      ),
      dataIndex: 'culinaryApplications',
      key: 'culinaryApplications',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (culinaryApplications) => (
        <Tooltip placement="topLeft" title={culinaryApplications}>
          {culinaryApplications}
        </Tooltip>
      ),
    },
    {
      title: () => <Tooltip title={i18n.t('fileImport.certificates')}>{i18n.t('fileImport.certificates')}</Tooltip>,
      dataIndex: 'certifications',
      key: 'certifications',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (certificates) => (
        <Tooltip placement="topLeft" title={certificates}>
          {certificates}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('mediaWidgetColumn.specificationSheet')}>
          {i18n.t('mediaWidgetColumn.specificationSheet')}
        </Tooltip>
      ),
      dataIndex: 'specificationSheet',
      key: 'specificationSheet',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (specificationSheet) => (
        <Tooltip placement="topLeft" title={specificationSheet}>
          {specificationSheet}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('mediaWidgetColumn.additionalResources')}>
          {i18n.t('mediaWidgetColumn.additionalResources')}
        </Tooltip>
      ),
      dataIndex: 'recipes',
      key: 'recipes',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (additionalResources) => (
        <Tooltip placement="topLeft" title={additionalResources}>
          {additionalResources}
        </Tooltip>
      ),
    },
    {
      title: () => <Tooltip title={i18n.t('infoColumn.aboutTheBrand')}>{i18n.t('infoColumn.aboutTheBrand')}</Tooltip>,
      dataIndex: 'aboutBrand',
      key: 'aboutBrand',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (aboutTheBrand) => (
        <Tooltip placement="topLeft" title={aboutTheBrand}>
          {aboutTheBrand}
        </Tooltip>
      ),
    },

    {
      title: () => (
        <Tooltip title={i18n.t('mediaWidgetColumn.productImages')}>{i18n.t('mediaWidgetColumn.productImages')}</Tooltip>
      ),
      dataIndex: 'productImages',
      key: 'productImages',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (productImages) => (
        <Tooltip placement="topLeft" title={productImages}>
          {productImages}
        </Tooltip>
      ),
    },
    {
      title: () => (
        <Tooltip title={i18n.t('mediaWidgetColumn.msdsSheet')}>{i18n.t('mediaWidgetColumn.msdsSheet')}</Tooltip>
      ),
      dataIndex: 'msdsSheet',
      key: 'msdsSheet',
      className: 'desc-col',
      width: '12.5rem',
      ellipsis: true,
      render: (msdsSheet) => (
        <Tooltip placement="topLeft" title={msdsSheet}>
          {msdsSheet}
        </Tooltip>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setUploadItems(selectedRows);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelectAll: () => {
      setUploadItems(selectAllRows(uploadItems, uploadData));
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: uploadItems.map((row) => row?.key),
  };
  return bulkUploadFetching ? (
    <AppLoader show />
  ) : (
    <ReactResizeDetector handleHeight onResize={onResize} targetRef={this} id="file-import-resizer">
      <div className="item-content-wrapper">
        <div className="item-content file-import">
          <div className="header">
            <i className="icon fi flaticon-upload"></i>
            <div className="title">{i18n.t('header.importViaFile')}</div>
          </div>
          <div className="note">
            <i className="icon fi flaticon-information-button"></i>{' '}
            <div className="message">{i18n.t('fileImport.uploadInformationMessage')}</div>
            <div className="warning-message">{i18n.t('fileImport.uploadWarningMessage')}</div>
            <a
              onClick={() => {
                sendWorkbook();
              }}
              className="download-example"
            >
              {i18n.t('fileImport.downloadExample')}
            </a>
          </div>
          <div className="upload-wrapper">
            <div className="upload">
              <Dragger {...props} className="uploader">
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">{i18n.t('fileImport.fileUpload')}</p>
                <p className="ant-upload-hint">XLXS / {i18n.t('fileImport.maxSize')}: 3mb</p>
              </Dragger>
            </div>
            <div className="review">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={uploadData}
                pagination={{ pageSize: 50 }}
                scroll={{ x: '156rem', y: gridScrollHeight }}
              />
            </div>
            <div className="button-bar">
              <Button id="button-bar-clear-btn" className="btn item-action-btn invert" onClick={() => clear()}>
                {i18n.t('fileImport.clear')}
              </Button>
              <Button id="button-bar-upload-btn" className="btn item-action-btn submit" onClick={() => upload()}>
                {i18n.t('fileImport.submit')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ReactResizeDetector>
  );
}
export default FileImport;
