import React from 'react';

function Fab({ feature, id, attribute }) {
  const trimmedFeature = feature?.trim();

  return (
    <li
      id={`info-column-${id}-row-edit-description-list-item-${trimmedFeature}`}
      key={`info-column-${id}-row-edit-description-list-item-${trimmedFeature}`}
      className={`data description raw-text ${attribute?.identifier === 'gs1' ? 'gs1-font' : ''}`}
    >
      {feature}
    </li>
  );
}

export default Fab;
