export const FEATURES_AND_BENEFITS = 'FEATURES_AND_BENEFITS';
export const CERTIFICATION = 'CERTIFICATION';
export const SPECIFICATION_SHEET = 'SPECIFICATION_SHEET';
export const MSDS_SHEET = 'MSDS_SHEET';
export const ATTRIBUTE = 'ATTRIBUTES';
export const ITEM_SIZE = 'ITEM_SIZE';
export const LIFESTYLE_IMAGE = 'LIFESTYLE_IMAGE';
export const PRODUCT_IMAGE = 'PRODUCT_IMAGE';
export const GS1_IMAGE = 'GS1_IMAGE';
export const VIDEO = 'VIDEO';
export const RECIPES = 'RECIPES';
export const UNABBREVIATED_DESCRIPTION = 'UNABBREVIATED_DESCRIPTION';
export const ITEM_OVERVIEW = 'ITEM_OVERVIEW';
export const PREPARATION_AND_COOKING = 'PREPARATION_AND_COOKING';
export const PRODUCT_USAGE = 'PRODUCT_USAGE';
export const PACKAGING_AND_STORAGE = 'PACKAGING_AND_STORAGE';
export const SERVING_SUGGESTIONS = 'SERVING_SUGGESTIONS';
export const ABOUT_BRAND = 'ABOUT_BRAND';
export const NUTRITIONAL_INFORMATION = 'NUTRITIONAL_INFORMATION';

// New FABs
export const PRODUCT_DESCRIPTOR = 'PRODUCT_DESCRIPTOR';
export const PACKAGING_INFORMATION = 'PACKAGING_INFORMATION';
export const SIZE_AND_SHAPE_OF_PRODUCT = 'SIZE_AND_SHAPE_OF_PRODUCT';
export const YIELD_OR_SERVING_SIZE = 'YIELD_OR_SERVING_SIZE';
export const QUALITY_AND_FORMAT = 'QUALITY_AND_FORMAT';
export const PREPARATION_AND_COOKING_INSTRUCTIONS = 'PREPARATION_AND_COOKING_INSTRUCTIONS';
export const STORAGE_AND_USAGE = 'STORAGE_AND_USAGE';
export const HANDLING_INSTRUCTIONS = 'HANDLING_INSTRUCTIONS';
export const ADDITIONAL_PRODUCT_INFORMATION = 'ADDITIONAL_PRODUCT_INFORMATION';
export const MARKETING_STATEMENTS = 'MARKETING_STATEMENTS';
export const CULINARY_APPLICATIONS = 'CULINARY_APPLICATIONS';

// this covers the auto updatable FAB field keys except product descriptor
export const FABFieldsUpdatableKeys = {
  PACKAGING_INFORMATION: 'packagingInformation',
  SIZE_AND_SHAPE_OF_PRODUCT: 'sizeAndShapeOfProduct',
  YIELD_OR_SERVING_SIZE: 'yieldOrServingSize',
  QUALITY_AND_FORMAT: 'qualityAndFormat',
  PREPARATION_AND_COOKING_INSTRUCTIONS: 'preparationAndCookingInstructions',
  STORAGE_AND_USAGE: 'storageAndUsage',
  HANDLING_INSTRUCTIONS: 'handlingInstructions',
  ADDITIONAL_PRODUCT_INFORMATION: 'additionalProductInformation',
  MARKETING_STATEMENTS: 'marketingStatements',
  CULINARY_APPLICATIONS: 'culinaryApplications',
};
