// this has the supported file extensions and its mimetypes respectively
export const SUPPORTED_FILE_EXTENSIONS_TYPES = [
  { extension: '', type: '' },
  { extension: '.pdf', type: 'application/pdf' },
  { extension: '.jpg', type: 'image/jpeg' },
  { extension: '.jpeg', type: 'image/jpeg' },
  { extension: '.png', type: 'image/png' },
  { extension: '.mp4', type: 'video/mp4' },
];

export const UNSUPPORTED_SPECIAL_CHARACTERS = '[&$@=;:+,?\\\\{^}%`\\]\'">[~<#|]';

export const INVALID_FILE_TYPE = {
  errorType: 'Invalid ContentType',
  errorMessage: 'file upload failed due to unsupported file type.',
};
export const INVALID_FILE_NAME = {
  errorType: 'Invalid FileName',
  errorMessage: 'file upload failed due to unsupported file name.',
};

export const LIFESTYLE_PREFIX = 'item_images/lifestyle/';
export const SPECIFICATION_PREFIX = 'specifications/';
export const MSDS_PREFIX = 'msds/';
export const CERTIFICATES_PREFIX = 'certificates/';
export const PRODUCT_IMAGE_PREFIX = 'item_images/others/';
export const GS1_IMAGE_PREFIX = 'item_images/images/';
export const VIDEO_PREFIX = 'videos/';
export const VIDEO_THUMBNAIL_PREFIX = 'videos/thumbnails/';
export const RECIPE_PREFIX = 'recipes/';

export const COMMA_DELIMETER = ',';
export const SLASH_DELIMETER = '/';
export const UNDERSCORE_DELIMETER = '_';
export const PERIOD_DELIMETER = '.';
export const NEWLINE_DELIMETER = '\n';
export const ASTERIX = '*';

export const FILENAME_DELIMITER = '.';
export const HTTP_SUCCESS_WITH_NO_CONTENT = 204;
export const HTTP_SUCCESS = 200;
export const USAENG = 'USAENG';
export const CANFRE = 'CANFRE';

export const GS1 = 'gs1';
export const NUMERIC_REGEX = '/^d*.?d*$/';
export const P_REGEX = '\\S+p_*(fr|en)*.jpg$';
export const C_REGEX = '\\S+c_*(fr|en)*.jpg$';
export const M_REGEX = '\\S+m_*(fr|en)*.jpg$';

export const INVALID_SUPC_EXISTS = 'INVALID_SUPC_EXISTS';
export const DUPLICATE_SUPC_EXISTS = 'DUPLICATE_SUPC_EXISTS';
