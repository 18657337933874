/**
 * Generate modified user input as per the guard rails below - applicable to the entire content
 *    1. Add '• ' at the beginning of the content
 *    2. Replace any '*' with '•'
 *    3. Replace multiple consecutive bullet points '(•)\1+' with a single '•'
 *    4. Replace white spaces (except \n) with bullet points '•([^\S\n]+)?•' with a single '•'
 *    5. Add space after every bullet point without replacing anything
 * @param  {string | null} val?
 * @returns any
 */
const FABFieldModification = (val) => {
  if (val === undefined || val === null) return val;

  if (!val.startsWith('•')) {
    val = '• ' + val;
  }

  val = val.replace(/\*/g, '•');
  val = val.replace(/(•)\1+/g, '•');
  val = val.replace(/•([^\S\n]+)?•/g, '•');

  val = val.replace(/•(.)/g, (matched) => {
    if (matched.slice(1) !== ' ') return '• ' + matched?.slice(1) || '';

    return matched;
  });

  return val;
};

/**
 * Generate modified user input when saving the content as per the guard rails below - applicable to the entire content
 *    1. Replace with '\n' if any bullet point is followed by white spaces ending with '\n' or end of content '•([^\S\n]+)?(\n|$)'
 *    2. For remaining bullet points, if any white spaces are followed '•([^\S\n]+)?', then remove with '• '
 *    3. Preserve a single new line in between 2 non-empty content; '\n(\s+)?\n(\s+)?\n' -> '\n\n'
 *    4. Trim the content before saving to remove unnecessary leading or trailing white spaces and new lines
 *    5. If initial bullet point removed due to 1st point, then add '• ' to the beginning again
 * @param  {string | null} val?
 * @returns any
 */
export const FABFieldSaveModification = (val) => {
  if (val === undefined || val === null) return val;

  val = val.replace(/•([^\S\n]+)?(\n|$)/g, '\n');
  val = val.replace(/•([^\S\n]+)?/g, '• ');
  val = val?.replace(/\n(\s+)?\n(\s+)?\n/g, '\n\n');

  val = val.trim();

  if (!val.startsWith('•')) {
    val = '• ' + val;
  }

  return val;
};

/**
 * Generate modified user input when uploading the content as per the guard rails below - applicable to the entire content
 * When uploading a text content, both user updating guard rails and saving guard rails should be used to modify the content
 * @param  {string | null} val?
 * @returns any
 */
export const FABFieldUploadModification = (val) => {
  val = FABFieldModification(val);
  val = FABFieldSaveModification(val);

  // check if a new line with a value does not have a bullet point and if so add a bullet point to the beginng
  // secondly remove initial bullet point, if there's no content added
  val = val.replace(/^(?!\s*$)(?!•)(.+)/gm, '• $1').replace(/^•\s*$/, '');

  return val;
};

export default FABFieldModification;
